$headerHeight: 100px;
$navLogoHeight: $headerHeight - 20px;
$teamSelectorWidth: 300px;

.header-sticky {
  position: sticky;
  top: 0;
  z-index: 102;
}
header#header {
  .header-super-admin-impersonating {
    display: flex;
    flex-direction: row;
    gap: 40px;
    padding: 6px 20px;
    background-color: #f6d30d;
    button.header-super-admin-impersonating-close-button {
      padding: 0;
    }
    .top-banner-abca {
      font-size: 20px;
      @media only screen and (min-width: 1600px) {
        font-size: 2em;
      }
    }
  }
  nav {
    .left-menu-wrapper {
      display: flex;
      height: $headerHeight;
      justify-content: center;
      align-items: center;
      .team-details-line {
        border: 1px solid #efefef;
        height: $navLogoHeight;
        margin-right: 20px;
      }
      .team-details-wrapper {
        display: flex;
        flex-direction: row;
        position: relative;
        width: $teamSelectorWidth;
        height: $navLogoHeight;
        .selected-team-wrapper {
          width: $teamSelectorWidth;
          display: flex;
          align-items: center;
          gap: 12px;
          cursor: pointer;
          img {
            height: $navLogoHeight;
            width: $navLogoHeight;
            object-fit: contain;
          }
          .team-details-text {
            flex: 1;
            .team-details-name,
            .team-details-players,
            .team-details-team {
              color: white;
              line-height: 1.2rem;
            }
            .team-details-name {
              font-weight: bold;
              font-size: 1.5rem;
            }
            .team-details-players,
            .team-details-team {
              font-size: 1rem;
            }
          }
        }
        .organization-team-chooser-wrapper {
          display: none;
          top: $navLogoHeight;
          width: $teamSelectorWidth;
          position: absolute;
          top: $navLogoHeight;
          .organization-team-chooser {
            margin-top: 4px;
            background-color: white;
            padding: 8px 0;
            border-radius: 4px;
            ul {
              list-style-type: none;
              margin: 0;
              padding: 0;
              li {
                margin: 8px;
                padding: 8px;
                border-radius: 4px;
                &.active {
                  background-color: #efefef;
                  border: none !important;
                }
                a {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  cursor: pointer;
                  &.active {
                    color: #162630 !important;
                    cursor: default;
                  }
                  // color: white !important;
                }
              }
            }
          }
        }
      }
      .team-details-wrapper:hover {
        .organization-team-chooser-wrapper {
          display: unset;
        }
      }
    }
    .u-header__nav-item-btn.dropdown {
      & > a {
        min-width: 12rem;
      }
      &:hover {
        .dropdown-menu-wrapper {
          display: block !important;
        }
      }
      .dropdown-menu-wrapper {
        position: absolute;
        z-index: 91;
        box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.3);
        display: none;
        .dropdown-menu {
          display: flex;
          position: relative;
          flex-direction: column;
          max-width: 12rem;
          div.dropdown-item {
            display: flex;
            &:hover a.edit-team-link {
              display: block;
            }
          }
          .dropdown-item {
            margin-left: -1rem;
            margin-right: -1rem;
            padding-left: 1.375rem;
            padding-right: 1.375rem;
            width: calc(100% + 2rem);
            font-size: 0.875rem;
            img.team-logo {
              height: 22px;
              width: 22px;
              object-fit: contain;
              margin-left: -6px;
              margin-right: 6px;
            }
            .team-wrapper {
              display: flex;
              justify-content: space-between;
              flex: 1;
              flex-wrap: wrap;
              .team-name {
                flex: 1;
                flex-wrap: wrap;
                font-size: 0.875rem;
                color: #162630 !important;
              }
              a {
                font-size: 0.875rem;
                font-weight: 900;
                &.edit-team-link {
                  display: none;
                }
              }
            }
            &.teams {
              &:hover {
                background-color: #efefef;
                .select-team-submenu {
                  // display: grid;
                }
              }
              .select-team-submenu {
                display: grid;
                position: absolute;
                left: -12rem;
                width: 12rem;
                top: 0;
                max-height: 50vh;
                overflow-y: scroll;
                border-radius: 4px;
                box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.3);
                background: #efefef;
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
                &::-webkit-scrollbar {
                  display: none;
                }
                & > a:hover {
                  background: #fbfbfb;
                }
                ul {
                  list-style-type: none;
                  margin: 0;
                  padding: 0 0 0 0.875rem;
                  li {
                    margin: 0;
                    padding: 8px;
                    font-size: 0.875rem;
                    height: 45px;
                    &:last-of-type::after {
                      display: none;
                      content: none;
                    }
                    &:hover:not(.active) {
                      background: #fbfbfb;
                    }
                    &::after {
                      height: 1px;
                      background-color: #ccc;
                      position: relative;
                      bottom: -4px;
                      margin: 0px;
                      display: block;
                      content: '';
                    }
                    a:hover {
                      .select-team-line-wrapper {
                        .select-team-line-name {
                          color: #f6d30d !important;
                          font-weight: 900;
                        }
                        &.active {
                          .select-team-line-name {
                            color: #162630 !important;
                          }
                        }
                      }
                    }
                    .select-team-line-wrapper {
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      &.active {
                        .select-team-line-name {
                          font-weight: 900;
                        }
                      }
                      img.select-team-line-logo {
                        width: 22px;
                        height: 22px;
                        object-fit: contain;
                        margin-right: 8px;
                      }
                      .select-team-line-name {
                        flex: 1;
                        flex-wrap: wrap;
                        font-size: 0.875rem;
                        color: #162630 !important;
                      }
                    }
                    &.active {
                      border-bottom: none;
                      a {
                        cursor: default;
                      }
                    }
                  }
                }
                .select-team-submenu-header,
                .select-team-submenu-footer {
                  margin: 0;
                  padding: 8px;
                  font-size: 0.875rem;
                  height: 45px;
                  background-color: #efefef;
                  position: sticky;
                  font-family: 'AvinerHeavy';
                }
                .select-team-submenu-header {
                  top: 0px;
                  &::after {
                    height: 1px;
                    background-color: #ccc;
                    position: relative;
                    bottom: -4px;
                    margin: 0px;
                    display: block;
                    content: '';
                  }
                }
                .select-team-submenu-footer {
                  bottom: 0px;
                  cursor: pointer;
                  &::before {
                    height: 1px;
                    background-color: #ccc;
                    position: relative;
                    top: -8px;
                    margin: 0px;
                    display: block;
                    content: '';
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
