table.quiz-table {
  & > tbody > tr {
    cursor: pointer;
  }
}

.quiz-results-grid-container {
  display: flex;
  flex-direction: column;
  .quiz-results-grid-row {
    display: grid;
    justify-content: center;
    margin: auto;
    cursor: pointer;
    & > .quiz-results-grid-header {
      cursor: default;
    }
    & > .quiz-results-grid-header.quiz-results-question-number-header {
      text-align: center;
      cursor: help;
    }
    &:not(.header-row):hover {
      background-color: #efefef;
      .show-on-hover {
        visibility: visible;
      }
    }
    &.header-row:not(.sortable) {
      cursor: default;
    }
    .show-on-hover {
      visibility: hidden;
    }
  }
  .quiz-results-separator-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid black;
    text-align: center;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    cursor: pointer;
  }
}

.results-type-player {
  .quiz-results-grid-container {
    .quiz-results-grid-row {
      & > .quiz-results-grid-header.quiz-results-question-number-header {
        cursor: default;
      }
    }
  }
}
