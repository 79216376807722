/*********************************************************************************************************************************/
/********************************************************** I.M.P.O.R.T **********************************************************/
/*********************************************************************************************************************************/
/***************************************************************************************************/
/**************************************** V.A.R.I.A.B.L.E.S ****************************************/
/***************************************************************************************************/
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
@font-face {
  font-family: 'AvinerHeavy';
  src: url('../helpers/fonts/AvenirLTStd-Heavy.otf');
}
@font-face {
  font-family: 'AvinerLight';
  src: url('../helpers/fonts/AvenirLTStd-Light.otf');
} /***************************************************************************************************/
/**************************************** V.A.R.I.A.B.L.E.S ****************************************/
/***************************************************************************************************/
/***************************************************************************************************/
/******************************************* M.I.X.I.N.S *******************************************/
/***************************************************************************************************/
/***************************************************************************************************/
/******************************************* M.I.X.I.N.S *******************************************/
/***************************************************************************************************/
/***************************************************************************************************/
/************************************** M.E.D.I.A - Q.U.E.R.Y **************************************/
/***************************************************************************************************/
/***************************************************************************************************/
/************************************** M.E.D.I.A - Q.U.E.R.Y **************************************/
/***************************************************************************************************/
/***************************************************************************************************/
/**************************************** B.O.X - S.H.A.D.O.W **************************************/
/***************************************************************************************************/
.box-shadow-0 {
  box-shadow: none !important;
}

.box-shadow-1 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.box-shadow-1-half {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

.box-shadow-2 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.3);
}

.box-shadow-3 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.3);
}

.box-shadow-4 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
}

.box-shadow-5 {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
}

/***************************************************************************************************/
/**************************************** B.O.X - S.H.A.D.O.W **************************************/
/***************************************************************************************************/
/*********************************************************************************************************************************/
/********************************************************** I.M.P.O.R.T **********************************************************/
/*********************************************************************************************************************************/
/*********************************************************************************************************************************/
/******************************************************* O.V.E.R.R.I.D.E.S *******************************************************/
/*********************************************************************************************************************************/
/***************************************************************************************************/
/****************************************** G.E.N.E.R.A.L ******************************************/
/***************************************************************************************************/
.u-header__navbar-brand-default.six-tool-logo {
  height: 78px;
}
.six-tool-logo {
  object-fit: contain;
  max-height: unset !important;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'AvinerLight' !important;
  font-size: 1rem;
  color: #000;
  background: #fdfdfd;
  overflow-x: hidden;
  transition: 0.3s;
}

p {
  font-family: 'AvinerLight';
  margin-bottom: 0;
  word-spacing: 2px;
}

a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'AvinerHeavy';
  margin-bottom: 0;
  color: #000;
  font-weight: bold;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f3df88;
  color: inherit;
}

.fade:not(.show) {
  visibility: hidden;
}

@media (min-width: 1200px) {
  #page-home .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  #page-home .container {
    max-width: 1320px;
  }
}

/***************************************************************************************************/
/***************************************** /.G.E.N.E.R.A.L *****************************************/
/***************************************************************************************************/
/***************************************************************************************************/
/***************************************** C.A.R.O.U.S.E.L *****************************************/
/***************************************************************************************************/
/***************************************************************************************************/
/**************************************** /.C.A.R.O.U.S.E.L ****************************************/
/***************************************************************************************************/
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #ffffff;
}

/***************************************************************************************************/
/********************************************* F.O.R.M *********************************************/
/***************************************************************************************************/
label {
  text-transform: capitalize;
}

.question-main label,
.question-main label div {
  text-transform: none;
}

.form-control {
  background-color: #ffffff;
  padding: pxtorem(10) pxtorem(16);
  border-color: #e3e3e3;
}
.form-control:focus {
  border-color: #eccc43;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.form-control.invalid {
  border-color: #dc3545;
}

.btn-primary {
  background-color: #eccc43;
  color: #ffffff;
  border-color: #ebc835;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: #eac62c;
  color: #ffffff;
  border-color: #ebc93a;
}

.btn-outline-secondary {
  border-color: #08081c;
}
.btn-outline-secondary:hover,
.btn-outline-secondary:active,
.btn-outline-secondary:focus {
  background-color: #08081c;
}

.btn-outline-light {
  color: #626262;
  border-color: #c3c3c3;
}
.btn-outline-light:hover,
.btn-outline-light:active,
.btn-outline-light:focus {
  background-color: #c3c3c3;
}

.btn-fluid {
  width: 100%;
}

.input-group-text {
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  border-radius: 3rem;
  border-color: #e3e3e3;
}

.input-group .form-text {
  display: block;
  width: 100%;
  text-align: left;
  padding-left: 2.5rem;
}

.btn-group-sm > .btn,
.btn-sm {
  font-size: 12px;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  background-color: #08081c;
  color: #ffffff;
}

.form-control::placeholder {
  color: #929292 !important;
  opacity: 0.75;
}

.form-control:-ms-input-placeholder {
  color: #929292 !important;
  opacity: 0.75;
}

.form-control::-ms-input-placeholder {
  color: #929292 !important;
  opacity: 0.75;
}

.btn-xs {
  padding: 0.25rem 0.6125rem;
}

/***************************************************************************************************/
/******************************************** /.F.O.R.M ********************************************/
/***************************************************************************************************/
/***************************************************************************************************/
/********************************************* T.A.B.S *********************************************/
/***************************************************************************************************/
/***************************************************************************************************/
/******************************************** /.T.A.B.S ********************************************/
/***************************************************************************************************/
/***************************************************************************************************/
/******************************************** T.A.B.L.E ********************************************/
/***************************************************************************************************/
table thead th {
  text-transform: uppercase;
}

/***************************************************************************************************/
/******************************************* /.T.A.B.L.E *******************************************/
/***************************************************************************************************/
/***************************************************************************************************/
/******************************************** M.O.D.A.L ********************************************/
/***************************************************************************************************/
.modal-content {
  padding: 1.5rem 0.75rem;
  border-radius: 0;
}

.modal-header {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
}
.modal-header .modal-title {
  font-size: 1.3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'AvinerHeavy';
  line-height: 1;
}

.modal-header,
.modal-footer {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.modal-form-btn {
  margin-top: pxtorem(50) !important;
}

/***************************************************************************************************/
/******************************************* /.M.O.D.A.L *******************************************/
/***************************************************************************************************/
/***************************************************************************************************/
/**************************************** A.C.C.O.R.D.I.O.N ****************************************/
/***************************************************************************************************/
.accordion .card .card-header {
  cursor: pointer;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
}
.accordion .card .card-header[aria-expanded='true'] .card-header-caret {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.accordion .card .card-header .text-icon-left {
  margin-right: 0.75rem;
}
.accordion .card .card-header .card-header-caret {
  margin-left: auto;
}
@media only screen and (max-width: 767px) {
  .accordion .card .card-body {
    padding: 1rem;
  }
}

/***************************************************************************************************/
/*************************************** /.A.C.C.O.R.D.I.O.N ***************************************/
/***************************************************************************************************/
/***************************************************************************************************/
/************************************** /.D.A.T.E.P.I.C.K.E.R **************************************/
/***************************************************************************************************/
/***************************************************************************************************/
/*************************************** D.A.T.E.P.I.C.K.E.R ***************************************/
/***************************************************************************************************/
/***************************************************************************************************/
/*************************************** P.A.G.I.N.A.T.I.O.N ***************************************/
/***************************************************************************************************/
.pagination-wrapper {
  text-align: center;
}

.pagination-general,
.pagination {
  display: -webkit-inline-flex;
  display: -ms-inline-flex;
  display: inline-flex;
}
.pagination-general .page-item.active .page-link,
.pagination .page-item.active .page-link {
  background-color: #eccc43;
  color: #ffffff;
  border-color: #eccc43;
}
.pagination-general .page-item.disabled .page-link,
.pagination .page-item.disabled .page-link {
  color: #c3c3c3;
}
.pagination-general .page-item .page-link,
.pagination .page-item .page-link {
  color: inherit;
  border: 0;
  -webkit-border-radius: 0.25rem !important;
  -moz-border-radius: 0.25rem !important;
  border-radius: 0.25rem !important;
}

/***************************************************************************************************/
/************************************** /.P.A.G.I.N.A.T.I.O.N **************************************/
/***************************************************************************************************/
/***************************************************************************************************/
/***************************************** L.I.G.H.T.B.O.X *****************************************/
/***************************************************************************************************/
.lightbox-backdrop {
  z-index: 999 !important;
}

.chat-conversation-message-each .lightbox-container .lightbox-img-thumbnail {
  height: auto !important;
  width: auto !important;
  max-width: 12rem;
}

.lightbox-img-thumbnail {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

/***************************************************************************************************/
/**************************************** /.L.I.G.H.T.B.O.X ****************************************/
/***************************************************************************************************/
/******************************************** B.A.D.G.E ********************************************/
/***************************************************************************************************/
.badge-primary {
  background-color: #f3df88;
  color: inherit;
  border: 1px solid #eccc43;
}

.badge-warning {
  background-color: #fea117;
}

/***************************************************************************************************/
/******************************************* /.B.A.D.G.E *******************************************/
/***************************************************************************************************/
.custom-control-label:before,
.custom-control-label:after {
  top: 3px;
  box-shadow: none;
}

@media only screen and (min-width: 992px) {
  .px-lg-5 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }
}

.display-4 {
  font-size: 3.1rem !important;
}
@media only screen and (max-width: 576px) {
  .display-4 {
    font-size: 2.1rem !important;
  }
}

.border-radius-none {
  border-radius: 0 !important;
}

/*********************************************************************************************************************************/
/******************************************************* O.V.E.R.R.I.D.E.S *******************************************************/
/*********************************************************************************************************************************/
/*********************************************************************************************************************************/
/********************************************************* G.E.N.E.R.A.L *********************************************************/
/*********************************************************************************************************************************/
/**************************************************************************************************/
/****************************************** G.E.N.E.R.A.L ******************************************/
/***************************************************************************************************/
.divider {
  border: 0.8px solid #e8e8e8;
  margin: 50px 0;
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.section-gap {
  padding: 80px 0;
}
@media only screen and (max-width: 767px) {
  .section-gap {
    padding: 40px 0;
  }
}

.high-section-gap {
  padding: 200px 0;
}

.header-general,
.header-general-md,
.header-general-sm {
  color: #191919;
  font-size: pxtorem(28);
  font-weight: 500;
}

.header-general-md {
  font-size: pxtorem(26) !important;
}

.header-general-sm {
  font-size: pxtorem(22) !important;
}

.header-general-xs {
  font-size: pxtorem(18) !important;
}

.font-size-21 {
  font-size: 21px;
}

.font-size-small {
  font-size: 14px !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.text-muted {
  color: #8d8d8d !important;
}

.link-muted {
  color: #929292;
  text-decoration: none;
}
.link-muted:hover {
  color: #626262;
  text-decoration: none;
}

.link-secondary {
  color: #08081c;
}

.btn-icon {
  font-size: 0.825rem;
}

.btn-icon-left {
  margin-right: 0.75rem;
}

.btn-icon-right {
  margin-left: 0.75rem;
}

.btn-like-a {
  border: none;
  outline: none;
  box-shadow: none;
}
.btn-like-a:focus {
  box-shadow: none;
  outline: none;
}

.validation-message {
  display: block;
  font-size: 80%;
  width: 100%;
  margin-top: 0.25rem;
  text-align: left;
}

.validation-message-error {
  color: #dc3545;
}

.validation-message-success {
  color: #577d2a;
}

.input-group .validation-message {
  padding-left: 2.5rem;
}

.resend-link-message {
  display: block;
}
.resend-link-message .resend-link {
  color: #9f8410;
  cursor: pointer;
}
.resend-link-message .resend-link:hover {
  text-decoration: underline;
}

.btn-group-inline {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}
.btn-group-inline .btn:not(:last-child) {
  margin-right: 0.375rem;
}

.theme-color {
  color: #0e0e30 !important;
}

.text-orange {
  color: #fea117;
}

.bg-none {
  background: none !important;
}

.text-shadow {
  text-shadow: 1px 1px 1px lightgrey;
}

.social-icons {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
}
.social-icons a i {
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
}
.social-icons .icon {
  padding: 8px 10px;
}
.social-icons .icon i {
  color: white;
  font-size: 1.1rem;
}

.cta .social-icons a i {
  border: 1px solid black;
  width: 35px;
}

.scroll-to-top {
  position: fixed;
  z-index: 98;
  bottom: -60px;
  right: 8px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  color: white;
  background: #eccc43;
  cursor: pointer;
  transition: 0.5s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.scroll-to-top.top-arrow {
  bottom: 60px;
}
.scroll-to-top:hover {
  background: #fea117;
}

.facebook-bg-color {
  background: #4267b2;
}

.twitter-bg-color {
  background: #00acee;
}

.youtube-bg-color {
  background: #ab3f1b;
}

/***************************************************************************************************/
/****************************************** G.E.N.E.R.A.L ******************************************/
/**************************************************************************************************/
@media only screen and (max-width: 576px) {
  .w-50 {
    width: 100% !important;
  }
}

.theme-btn {
  font-size: 1rem;
  padding: 8px 20px;
  text-transform: uppercase;
  transition: 0.4s;
  border: none;
  font-weight: 500;
  border-radius: 5px;
  color: white;
  background: #fea117;
}
.theme-btn.big {
  background: #2c2b30;
  background: -moz-linear-gradient(top, #2c2b30 0%, #050407 100%);
  background: -webkit-linear-gradient(top, #2c2b30 0%, #050407 100%);
  background: linear-gradient(to bottom, #2c2b30 0%, #050407 100%);
  border-radius: 30px;
  padding: 10px 30px;
  box-shadow: 0px 21px 10px -10px rgba(0, 0, 0, 0.31);
  transition: all ease-in-out 300ms;
  color: #fff;
}
.theme-btn.big:hover {
  background: linear-gradient(to bottom, #2c2b30 0%, #050407 100%);
  box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
  transform: translate(0px, -10px) scale(1.1);
  color: #fff;
}
.theme-btn.bg-white {
  border: 1px solid transparent;
  color: #626262 !important;
}
.theme-btn.bg-white:hover {
  border: 1px solid white !important;
  background: none !important;
  color: white !important;
}
.theme-btn.outline {
  background: transparent;
  border: 1px solid white;
}
.theme-btn.outline:hover {
  background: white;
  color: black;
}
.theme-btn:hover {
  color: white;
  background: #08081c;
}
.theme-btn:focus {
  outline: none;
}

.outline-btn {
  color: white;
  display: inline-flex;
  align-items: center;
  font-weight: lighter;
  font-size: 18px;
  border: 1px solid white;
  font-family: 'AvinerHeavy';
  border-radius: 50px;
  padding: 0.5rem 1rem;
  outline: none !important;
  box-shadow: none !important;
}
.outline-btn:hover {
  background: white;
  color: black;
}

.primary-btn {
  color: black !important;
  display: inline-flex !important;
  align-items: center;
  font-size: 18px !important;
  font-weight: lighter !important;
  background: #eccc43 !important;
  font-family: 'AvinerHeavy';
  border-radius: 50px !important;
  padding: 0.5rem 1rem !important;
  outline: none !important;
  box-shadow: none !important;
}
.primary-btn .material-symbols-outlined {
  margin-right: 5px;
  font-size: 18px;
}
.primary-btn:hover {
  background: #e5be17;
  color: black;
}

.secondary-btn {
  color: white;
  display: inline-flex;
  font-size: 18px;
  font-weight: lighter;
  background: #08081c;
  font-family: 'AvinerHeavy';
  border-radius: 50px;
  padding: 0.5rem 2rem;
  outline: none !important;
  box-shadow: none !important;
  align-items: center;
}
.secondary-btn .material-symbols-outlined {
  margin-right: 5px;
  font-size: 18px;
}
.secondary-btn:hover {
  background: #131344;
  color: white;
}

.text-orange {
  color: #fea117 !important;
}

.bg-orange {
  background: #fea117 !important;
}
.bg-orange.btn {
  box-shadow: none;
}
.bg-orange.btn:hover {
  background: #08081c !important;
}

.border-none {
  border: none !important;
}

.owl-dots {
  display: flex;
  margin-top: 10px;
  justify-content: center;
}
.owl-dots .owl-dot {
  margin: 5px;
}
.owl-dots .owl-dot.active span {
  background: #fea117;
}
.owl-dots .owl-dot span {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
}

.hide-overflow {
  overflow: hidden;
}

.bg-white {
  background: white !important;
}

.rotate-180 {
  transform: rotate(180deg);
}

.nice-select.table_number {
  background: none;
  border-radius: 3px;
}
.nice-select .list {
  width: 100%;
}

.datepicker {
  padding: 0;
}
.datepicker * {
  font-size: 12px;
}
.datepicker .datepicker-days .datepicker-switch {
  padding: 12px 0;
}
.datepicker .datepicker-days .dow {
  padding: 6px 8px;
  border: 1px solid lightgrey;
  color: #fea117;
}
.datepicker .datepicker-days .day {
  padding: 8px 10px;
  border: 1px solid lightgrey;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.datepicker .datepicker-days .day.active {
  background: #fea117;
}
.datepicker .datepicker-days .day.active:hover {
  background: #fea117;
}
.datepicker .datepicker-days .day.today {
  background: #eccc43;
  color: white;
}
.datepicker .datepicker-days .day.today:hover {
  background: #eccc43;
  color: white;
}
.datepicker .datepicker-days .day:hover {
  background: #eccc43;
  color: white;
}
.datepicker .datepicker-days .day.disabled:hover {
  cursor: not-allowed;
  background: none;
  color: #999;
}

.title-wrapper .title {
  font-weight: bold;
  display: inline-block;
  position: relative;
  padding-bottom: 20px;
}
.title-wrapper .title h1 {
  font-size: 30px;
  color: #fea117;
  line-height: 25px;
}
.title-wrapper .title p {
  text-transform: capitalize;
  font-size: 2rem;
}
@media only screen and (max-width: 576px) {
  .title-wrapper .title p {
    font-size: 1.5rem;
  }
}

.owl-nav {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}
.owl-nav div {
  border-radius: 50% !important;
  height: 40px;
  width: 40px;
  line-height: 40px;
  font-size: 1.2rem;
  background: #e8e8e8 !important;
  color: grey !important;
  text-align: center;
  margin: 5px;
}

@media only screen and (max-width: 767px) {
  .display-3 {
    font-size: 2.1rem;
  }
}

.text-decoration-none {
  text-decoration: none;
  border-bottom: 0 !important;
}

.fa-2x {
  font-size: 1.3em;
}

.modal .modal-header button {
  position: absolute;
  right: 0;
  top: 0;
  background: #eccc43;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  line-height: 20px;
  opacity: 1;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  outline: none;
}
.modal .modal-header button:hover {
  opacity: 0.6;
}
@media only screen and (max-width: 576px) {
  .modal .modal-header button {
    top: 0;
    right: 8;
  }
}

.text-hover:hover {
  text-decoration: underline;
}

.hide-overflow {
  overflow: hidden;
}

.rotate-180 {
  transform: rotate(180deg);
}

.nice-select option {
  color: black;
}
.nice-select span.current {
  opacity: 0.7;
}
.nice-select .list {
  max-height: 220px;
  overflow-y: auto;
  scrollbar-width: thin;
}
.nice-select .list::-webkit-scrollbar {
  width: 10px;
  background: #e8e8e8;
}
.nice-select .list::-webkit-scrollbar-thumb {
  background: #c3c3c3;
}

.datepicker {
  padding: 0;
}
.datepicker * {
  font-size: 12px;
}
.datepicker .datepicker-days .datepicker-switch {
  padding: 12px 0;
}
.datepicker .datepicker-days .dow {
  padding: 6px 8px;
  border: 1px solid lightgrey;
  color: #fea117;
}
.datepicker .datepicker-days .day {
  padding: 8px 10px;
  border: 1px solid lightgrey;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.datepicker .datepicker-days .day.active {
  background: #fea117;
}
.datepicker .datepicker-days .day.active:hover {
  background: #fea117;
}
.datepicker .datepicker-days .day.today {
  background: #eccc43;
  color: white;
}
.datepicker .datepicker-days .day.today:hover {
  background: #eccc43;
  color: white;
}
.datepicker .datepicker-days .day:hover {
  background: #eccc43;
  color: white;
}
.datepicker .datepicker-days .day.disabled:hover {
  cursor: not-allowed;
  background: none;
  color: #999;
}

.font-heading {
  font-family: 'AvinerHeavy';
}

.pagination .page-link {
  color: #eccc43;
  padding: 0.5rem 0.9rem;
  outline: none;
  box-shadow: none;
}
.pagination .page-link:hover {
  background: #eccc43;
  color: white;
  border: 1px solid transparent;
}
.pagination .page-link.current {
  background: #fea117;
  color: white;
  border: 1px solid #fea117;
}

.custom-switch .custom-control-label {
  cursor: pointer;
}
.custom-switch .custom-control-label::after {
  top: 5.5px !important;
  left: -49px !important;
  width: 18px !important;
  height: 18px !important;
  background-color: #c3c3c3;
  border-radius: 50px !important;
}
.custom-switch .custom-control-label::before {
  left: -3.25rem !important;
  width: 2.8rem !important;
  border-radius: 50px !important;
  height: calc(1.4rem + 1px) !important;
  border: 1px solid #c3c3c3;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(20px);
}

.custom-switch
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: none;
  box-shadow: none;
}

.custom-switch .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-switch input:checked ~ .custom-control-label::before {
  color: #fea117 !important;
  border-color: #fea117 !important;
  background-color: white !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fea117 !important;
}

input:checked ~ .custom-control-label::before {
  color: #fea117 !important;
  border-color: #fea117 !important;
  background-color: #fea117 !important;
}

.action .remove-btn {
  border: none;
  background: none;
}
.action .remove-btn:focus {
  outline: none;
}

.cs-scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
}
.cs-scrollbar::-webkit-scrollbar {
  width: 5px;
  background: #e2e2e2;
  border-radius: 5px;
}
.cs-scrollbar::-webkit-scrollbar-thumb {
  background: #bcbcbc;
  border-radius: 5px;
  border-radius: 5px;
}
@media only screen and (max-width: 576px) {
  .cs-scrollbar::-webkit-scrollbar {
    width: 2px;
  }
}

.material-symbols-outlined {
  margin: 0;
}

@media only screen and (max-width: 1199px) {
  .title h1 {
    font-size: 65px !important;
    line-height: 75px !important;
  }
}
@media only screen and (max-width: 991px) {
  .title h1 {
    font-size: 35px !important;
    line-height: 50px !important;
  }
  .title h2 {
    font-size: 35px !important;
    line-height: 40px !important;
  }
  p {
    font-size: 16px !important;
  }
}
/*********************************************************************************************************************************/
/********************************************************* G.E.N.E.R.A.L *********************************************************/
/*********************************************************************************************************************************/
/*********************************************************************************************************************************/
/****************************************************** C.O.N.T.E.X.T.U.A.L ******************************************************/
/*********************************************************************************************************************************/
/***************************************************************************************************/
/*************************************** C.O.N.T.E.X.T.U.A.L ***************************************/
/***************************************************************************************************/
.clear-float:before,
.clear-float:after,
.clear-fix:before,
.clear-fix:after,
.clear:before,
.clear:after {
  content: '';
  display: table;
}
.clear-float:after,
.clear-fix:after,
.clear:after {
  clear: both;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.fluid-height {
  height: 100%;
}

.fluid-height-min {
  min-height: 100%;
}

.fluid-width {
  width: 100% !important;
}

.fluid-width-min {
  min-width: 100%;
}

.no-margin,
.m-0 {
  margin: 0 !important;
}

.no-padding,
.p-0 {
  padding: 0 !important;
}

.text-info {
  display: block;
  font-size: 12px;
  margin-top: 0.5rem;
}

.text-left,
.align-left {
  text-align: left;
}

.text-center,
.align-center {
  text-align: center;
}

.text-right,
.align-right {
  text-align: right;
}

.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.uppercase,
.text-uppercase {
  text-transform: uppercase;
}

.lowercase,
.text-lowercase {
  text-transform: lowercase;
}

.capitalize,
.text-capitalize {
  text-transform: capitalize;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
  opacity: 0 !important;
}

.visible {
  visibility: visible !important;
  opacity: 1 !important;
}

.remove,
.hide {
  display: none;
}

.d-flex {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}

.d-flex-center {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
}

.color-primary {
  color: #eccc43;
}

.color-success {
  color: #649130;
}

.color-error {
  color: #cc0000;
}

.bg-lightest {
  background-color: #fefefe;
}

.stretch {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.img-fluid {
  display: block;
  max-width: 100%;
  height: auto;
}

.img-centered {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.cursor-pointer {
  cursor: pointer;
  padding: 0 !important;
}

ul.clean {
  list-style: none;
  margin: 0;
  padding: 0;
}

.relative {
  position: relative;
}

.transition-general {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.box-shadow-general {
  -webkit-box-shadow: 0 0 0.5rem #e8e8e8;
  -moz-box-shadow: 0 0 0.5rem #e8e8e8;
  box-shadow: 0 0 0.5rem #e8e8e8;
}

.link-general {
  color: #eccc43;
}
.link-general:hover {
  color: #eac62c;
}

.cursor {
  cursor: pointer;
}

@media only screen and (max-width: 576px) {
  .p-sm-0 {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
}

@media only screen and (max-width: 576px) {
  .px-sm-2 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media only screen and (max-width: 576px) {
  .my-sm-1 {
    margin: 0.25rem 0 !important;
  }
}

@media only screen and (max-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 576px) {
  .py-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
}

@media only screen and (max-width: 576px) {
  .py-5 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
  }
}

.mh-600 {
  max-height: 600px;
}

.mh-500 {
  max-height: 500px;
}

/***************************************************************************************************/
/*************************************** C.O.N.T.E.X.T.U.A.L ***************************************/
/***************************************************************************************************/
/*********************************************************************************************************************************/
/****************************************************** C.O.N.T.E.X.T.U.A.L ******************************************************/
/*********************************************************************************************************************************/
/*********************************************************************************************************************************/
/*********************************************************** S.T.Y.L.E ***********************************************************/
/*********************************************************************************************************************************/
.header {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding: 30px 0;
  z-index: 5;
}
.header .header-inner .nav-wrapper {
  display: flex;
  margin-left: auto;
}
.header .header-inner .nav-wrapper .close-btn-box {
  display: none;
}
@media only screen and (max-width: 1199px) {
  .header .header-inner .nav-wrapper {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background: #162630;
    text-align: center;
    margin: unset;
    width: 100%;
    flex-direction: column;
  }
  .header .header-inner .nav-wrapper .navbar,
  .header .header-inner .nav-wrapper .nav {
    width: 100%;
  }
  .header .header-inner .nav-wrapper .close-btn-box {
    display: block;
  }
  .header .header-inner .nav-wrapper .close-btn-box .close-btn {
    display: inline-block;
    text-align: right;
    padding: 1rem !important;
  }
  .header
    .header-inner
    .nav-wrapper
    .close-btn-box
    .close-btn
    .material-symbols-outlined {
    font-size: 30px;
  }
}
.header .header-inner .nav-wrapper .nav .nav-item .nav-link {
  color: white;
  font-size: 20px;
  font-weight: lighter;
  padding: 10px 30px;
}
.header .header-inner .nav-wrapper .nav .nav-item .nav-link:hover {
  color: #eccc43;
}
@media only screen and (max-width: 1199px) {
  .header .header-inner .nav-wrapper .nav .nav-item .nav-link {
    padding: 30px 10px;
  }
}
@media only screen and (max-width: 991px) {
  .header .header-inner .nav-wrapper .nav .nav-item .nav-link {
    padding: 15px 10px;
  }
}
@media only screen and (max-width: 1199px) {
  .header .header-inner .nav-wrapper .nav {
    flex-direction: column;
  }
}
.header .header-inner .main-logo {
  object-fit: contain;
  max-height: 65px;
  max-width: 170px;
}
@media only screen and (max-width: 991px) {
  .header .header-inner .main-logo {
    max-width: 100px;
  }
}
.header .header-inner .hamburger {
  display: none;
}
@media only screen and (max-width: 1199px) {
  .header .header-inner .hamburger {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
  }
  .header .header-inner .hamburger .material-symbols-outlined {
    color: white;
    font-size: 50px;
  }
}
@media only screen and (max-width: 1199px) and (max-width: 991px) {
  .header .header-inner .hamburger .material-symbols-outlined {
    font-size: 30px;
  }
}
.header .header-inner .login-btns .signup-btn {
  margin-right: 15px;
}
@media only screen and (max-width: 991px) {
  .header .header-inner .login-btns .signup-btn {
    margin-right: 50px;
  }
}
.header .header-inner .login-btns .signup-btn .material-symbols-outlined {
  margin-right: 5px;
  font-size: 18px;
}
.header .header-inner .login-btns .login-btn .material-symbols-rounded {
  margin-right: 5px;
  font-size: 18px;
}
@media only screen and (max-width: 1199px) {
  .header .header-inner .login-btns {
    flex-direction: row;
    justify-content: center;
  }
}
@media only screen and (max-width: 991px) {
  .header .header-inner .login-btns {
    padding: 20px 15px;
  }
}

section.hero {
  position: relative;
}
@media only screen and (max-width: 1199px) {
  section.hero {
    background: #08081c;
  }
}
section.hero .hero-text p {
  color: white;
}
section.hero .hero-text {
  color: white;
  max-width: 100vw;
}
@media (min-width: 768px) {
  section.hero .hero-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  section.hero .hero-text p {
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: 15px;
  }
  section.hero .hero-text {
    max-width: 300px;
  }
  section.hero .hero-text .title h1 {
    font-size: 22px !important;
    line-height: 26px !important;
  }
  section.hero h4 {
    font-size: 1rem;
  }
  section.hero .hero-text .content {
    padding: 0 !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  section.hero .hero-text p {
    font-size: 1rem;
    line-height: 1.3rem;
  }
  section.hero .hero-text {
    max-width: 400px;
  }
  section.hero .hero-text .title h1 {
    font-size: 32px !important;
    line-height: 38px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  section.hero .hero-text {
    max-width: 480px;
  }
}
@media only screen and (max-width: 1199px) {
  section.hero .hero-text {
    padding: 50px 0;
  }
}
@media only screen and (min-width: 1400px) {
  section.hero .hero-text {
    max-width: 500px;
  }
}
section.hero .hero-text .title h1 {
  color: white;
  text-transform: capitalize;
  font-size: 75px;
  line-height: 82px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  section.hero .hero-text .title h1 {
    font-size: 42px;
    line-height: 54px;
  }
}

section.hero .hero-text .title h1 span {
  color: #eccc43;
}
section.hero .hero-text .subtitle {
  position: relative;
  padding-left: 111px;
  font-size: 20px;
}
section.hero .hero-text .subtitle::before {
  content: '';
  position: absolute;
  top: 50%;
  translate: 0 -50%;
  left: 0;
  width: 100px;
  height: 2px;
  background: #fff;
}
section.hero .hero-text .content {
  padding: 18px 0;
  font-size: 21px;
}

section.app-overview {
  background: url(../helpers/img/overview-bg.png) no-repeat;
  background-size: cover;
  padding: 110px 0;
  margin-top: -60px;
}
@media only screen and (max-width: 991px) {
  section.app-overview {
    padding-bottom: 60px;
  }
}
section.app-overview .title h2 {
  font-size: 55px;
  line-height: 65px;
}
section.app-overview .content {
  font-size: 18px;
  line-height: 30px;
  margin: 30px 0;
}
section.app-overview .content p {
  font-family: 'AvinerHeavy';
}
section.app-overview .btn-wrap .btn {
  padding: 0.5rem 1.8rem;
}

section.why-6tool {
  padding: 90px 0;
}
@media only screen and (max-width: 991px) {
  section.why-6tool {
    padding-top: 60px;
  }
}
section.why-6tool .title {
  margin-bottom: 30px;
}
section.why-6tool .title h2 {
  font-size: 50px;
  line-height: 60px;
}
section.why-6tool .reasons .reason-each {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border: 2px solid #c1c1c1;
  border-radius: 5px;
}
section.why-6tool .reasons .reason-each:last-child {
  margin-bottom: 0;
}
section.why-6tool .reasons .reason-each .icon {
  flex: 0 0 70px;
  height: 70px;
  margin-right: 20px;
}
section.why-6tool .reasons .reason-each .text {
  padding-right: 18px;
}
section.why-6tool .reasons .reason-each .text p {
  font-size: 20px;
  font-family: 'AvinerHeavy';
  margin-bottom: 0;
}
section.why-6tool .btn-wrap {
  margin-top: 30px;
}
section.why-6tool .btn-wrap .btn {
  padding: 0.5rem 2rem;
}

section.how-it-works {
  padding: 110px 0;
  background: #08081c;
}
@media only screen and (max-width: 991px) {
  section.how-it-works {
    padding: 70px 0;
  }
}
section.how-it-works .container {
  position: relative;
}
section.how-it-works .title {
  margin-bottom: 75px;
}
@media only screen and (max-width: 991px) {
  section.how-it-works .title {
    margin-bottom: 50px;
  }
}
section.how-it-works .title h2 {
  font-size: 55px;
  line-height: 65px;
  color: white;
}
section.how-it-works .block {
  text-align: center;
}
@media only screen and (max-width: 991px) {
  section.how-it-works .block {
    padding: 50px 0;
  }
}
section.how-it-works .block .icon {
  width: 100px;
  height: 90px;
  margin: 0 auto 25px;
  padding: 0 20px;
  background: #08081c;
  position: relative;
}
@media only screen and (max-width: 991px) {
  section.how-it-works .block .icon {
    margin: 0 auto 15px;
  }
}
section.how-it-works .block .icon::before {
  content: '';
  background: url(../helpers/img/right-arrow-icon.png) center;
  background-size: contain;
  position: absolute;
  left: -160%;
  top: 50%;
  translate: 0 -50%;
  height: 30px;
  width: 30px;
}
@media only screen and (max-width: 991px) {
  section.how-it-works .block .icon::before {
    left: -85%;
  }
}
@media only screen and (max-width: 767px) {
  section.how-it-works .block .icon::before {
    top: -60px;
    left: 50% !important;
    translate: -50% 0;
    rotate: 90deg;
  }
}
section.how-it-works .block .icon.first::before {
  left: -100%;
}
@media only screen and (max-width: 991px) {
  section.how-it-works .block .icon.first::before {
    left: -50%;
  }
}
section.how-it-works .block .icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
section.how-it-works .block .text {
  color: white;
  padding: 0 22px;
}
section.how-it-works .block .text p {
  color: white !important;
}
@media only screen and (max-width: 991px) {
  section.how-it-works .block .text {
    position: relative;
    z-index: 2;
  }
  section.how-it-works .block .text::before {
    position: absolute;
    content: '';
    background: #08081c;
    width: 100%;
    top: -25px;
    left: 0;
    height: calc(100% + 50px);
    z-index: -1;
  }
}
section.how-it-works .block .text p {
  font-size: 26px;
  line-height: 31px;
  font-family: 'AvinerHeavy';
}
section.how-it-works .line-arrow {
  border-top: 2px dashed #fcfcfc;
  position: absolute;
  top: 148px;
  left: 0;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  section.how-it-works .line-arrow {
    top: 193px;
  }
}
@media only screen and (max-width: 767px) {
  section.how-it-works .line-arrow {
    height: calc(100% - 40px);
    width: 2px;
    border-left: 2px dashed #fcfcfc;
    left: 50%;
    translate: -50% 0;
    border-top: unset;
    top: 65px;
  }
}

section.tool-control {
  padding: 90px 0 400px;
  margin-bottom: -216px;
  background: url(../helpers/img/background-ctrl.png);
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (max-width: 1199px) {
  section.tool-control {
    padding-bottom: 300px;
  }
}
@media only screen and (max-width: 991px) {
  section.tool-control {
    padding: 60px 0 280px;
  }
}
section.tool-control .title {
  margin-bottom: 50px;
}
@media only screen and (max-width: 991px) {
  section.tool-control .title {
    margin-bottom: 25px;
  }
}
section.tool-control .title h2,
section.logos .title h2 {
  font-size: 55px;
  line-height: 60px;
  text-align: center;
}
section.tool-control .icon-big {
  position: relative;
  max-width: 575px;
  margin: auto;
}
@media only screen and (max-width: 1199px) {
  section.tool-control .icon-big .tool-num {
    display: none;
  }
}
section.tool-control .icon-big .tool-num p {
  position: absolute;
  font-family: 'AvinerHeavy';
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 20px;
  background: white;
  border: 1px solid black;
  border-radius: 50%;
}
section.tool-control .icon-big .tool-num p.a {
  top: -5px;
  left: 40px;
}
section.tool-control .icon-big .tool-num p.b {
  top: 50%;
  translate: 0 -50%;
  left: -15px;
}
section.tool-control .icon-big .tool-num p.c {
  bottom: 50px;
  left: 75px;
}
section.tool-control .icon-big .tool-num p.d {
  bottom: -20px;
  left: 50%;
  translate: -50% 0;
}
section.tool-control .icon-big .tool-num p.e {
  bottom: 50px;
  right: 75px;
}
section.tool-control .icon-big .tool-num p.f {
  top: 50%;
  translate: 0 -50%;
  right: -15px;
}
section.tool-control .icon-big .tool-num p.g {
  top: -5px;
  right: 40px;
}
@media only screen and (max-width: 1199px) {
  section.tool-control .icon-big .tool-list {
    display: none;
  }
}
section.tool-control .icon-big .tool-list p {
  position: absolute;
  font-size: 20px;
  line-height: 24px;
  font-family: 'AvinerHeavy';
}
section.tool-control .icon-big .tool-list p.a {
  top: -5px;
  left: -40%;
  max-width: 257px;
  text-align: right;
}
section.tool-control .icon-big .tool-list p.b {
  top: 50%;
  translate: 0 -50%;
  left: -54%;
  text-align: right;
  max-width: 280px;
}
section.tool-control .icon-big .tool-list p.c {
  bottom: 50px;
  left: -42%;
  max-width: 295px;
  text-align: right;
}
section.tool-control .icon-big .tool-list p.d {
  bottom: -110px;
  left: 50%;
  translate: -50% 0;
  text-align: center;
  max-width: 295px;
}
section.tool-control .icon-big .tool-list p.e {
  bottom: 50px;
  right: -38%;
  max-width: 270px;
}
section.tool-control .icon-big .tool-list p.f {
  top: 50%;
  translate: 0 -50%;
  right: -52%;
  max-width: 270px;
}
section.tool-control .icon-big .tool-list p.g {
  top: -5px;
  right: -43%;
  max-width: 270px;
}
section.tool-control .icon-big .num-lists {
  display: none;
}
@media only screen and (max-width: 1199px) {
  section.tool-control .icon-big .num-lists {
    display: block;
    margin-top: 25px;
  }
  section.tool-control .icon-big .num-lists p {
    font-size: 20px;
    display: flex;
    align-items: center;
    line-height: 24px;
    font-family: 'AvinerHeavy';
    margin-bottom: 20px;
  }
  section.tool-control .icon-big .num-lists p .num {
    width: 35px;
    flex: 0 0 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-size: 16px;
    background: white;
    border: 1px solid black;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }
}
section.tool-control .icon-big img {
  max-width: 575px;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

section.ready-to-start {
  position: relative;
}
section.ready-to-start .bg-layout {
  background: url(../helpers/img/background-layer.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 60px 20px;
}
@media only screen and (max-width: 991px) {
  section.ready-to-start .bg-layout {
    padding: 30px 20px;
  }
}
section.ready-to-start .bg-layout .icon-box img {
  width: 50px;
  height: 60px;
  margin: auto;
  object-fit: contain;
}
section.ready-to-start .bg-layout .heading {
  max-width: 630px;
  margin: 20px auto 30px;
}
section.ready-to-start .bg-layout .heading h2 {
  font-size: 50px;
  line-height: 57px;
}
@media only screen and (max-width: 991px) {
  section.ready-to-start .bg-layout .heading h2 {
    font-size: 30px;
    line-height: 35px;
  }
}
section.ready-to-start .bg-layout .btn-wrap .btn {
  padding: 0.7rem 2.5rem;
}

.footer {
  background: #162630;
  padding-top: 60px;
}

#page-home + .footer {
  padding-top: 290px;
  margin-top: -185px;
}

@media only screen and (max-width: 991px) {
  .footer {
    padding-top: 260px;
  }
}
.footer .footer-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 991px) {
  .footer .footer-top {
    flex-direction: column;
  }
}
.footer .footer-top .footer-logo img {
  object-fit: contain;
  width: 180px;
  height: 60px;
}
@media only screen and (max-width: 991px) {
  .footer .footer-top .footer-logo img {
    width: 130px;
  }
}
@media only screen and (max-width: 991px) {
  .footer .footer-top .footer-nav .nav {
    justify-content: center;
    margin-top: 30px;
  }
}
.footer .footer-top .footer-nav .nav-item .nav-link {
  color: white !important;
  font-size: 18px;
  text-transform: uppercase;
  padding: 0.5rem;
}
@media only screen and (min-width: 992px) {
  .footer .footer-top .footer-nav .nav-item .nav-link {
    padding: 0.5rem 1.3rem;
  }
}
.footer .footer-top .footer-nav .nav-item .nav-link:hover {
  color: #eccc43;
}
@media only screen and (max-width: 991px) {
  .footer .footer-top .footer-nav .nav-item .nav-link {
    font-size: 16px;
  }
}
.footer .footer-top .footer-nav .nav-item:last-child .nav-link {
  padding-right: 0;
}
.footer .copyright span,
.footer .copyright .footer-copyright-text,
.footer .copyright .footer-copyright-text > a {
  color: white !important;
  font-size: 18px !important;
}
.btn.primary-btn:hover {
  color: white !important;
}
.footer .copyright .footer-copyright-text > a:hover {
  color: #f6d30d !important;
}

.footer .copyright {
  padding: 30px 0;
  margin-top: 30px;
  border-top: 2px solid #2e3c45;
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px !important;
}
@media only screen and (max-width: 991px) {
  .footer .copyright {
    font-size: 16px;
  }
}
@media only screen and (max-width: 991px) {
  .footer .copyright {
    flex-direction: column;
    align-items: center;
  }
}
.footer .social-icons {
  font-family: 'AvinerHeavy';
}
@media only screen and (max-width: 991px) {
  .footer .social-icons {
    margin-top: 15px;
  }
}
.footer .social-icons .icon {
  color: white !important;
  border: 1px solid white;
  border-radius: 50%;
  margin-left: 8px;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
}
.footer .social-icons .icon svg {
  fill: white;
  width: 18px;
  height: 18px;
  transition: 0.4s;
}
.footer .social-icons .icon:hover {
  background: #eccc43;
  border: 1px solid #eccc43;
}
.footer .social-icons .icon:hover svg {
  fill: #162630;
}

/*********************************************************************************************************************************/
/*********************************************************** S.T.Y.L.E ***********************************************************/
/*********************************************************************************************************************************/

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@500;700&display=swap');

section.reviews {
  padding: 90px 0 45px;
}

@media (max-width: 768px) {
  section.reviews {
    padding: 60px 0 45px;
  }
}

section.reviews .title {
  margin-bottom: 40px;
}

section.reviews .title h2 {
  font-size: 55px;
  line-height: 60px;
  font-weight: bold;
}

section.reviews .item {
  padding: 40px 15px;
}

@media (max-width: 768px) {
  section.reviews .item {
    padding: 40px 8px;
  }
}

section.reviews .item .card {
  position: relative;
  background: url('../img/quote-fade.png');
  background-size: 115% 125%;
  background-position: center;
  z-index: 2;
  padding: 75px 30px 30px;
  border: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  border-bottom: 3px solid #eccc43;
}

section.reviews .item .card .card-inner {
  position: relative;
  z-index: 2;
}

section.reviews .item .card .quote-circle {
  position: absolute;
  top: -30px;
  width: 80px;
  border-radius: 50%;
  height: 80px;
  background: #eccc43;
  display: grid;
  place-items: center;
  z-index: 2;
}

section.reviews .item .card .quote-circle img {
  width: 45px;
  height: 45px;
  object-fit: contain;
}

section.reviews .item .card .stars {
  margin-bottom: 20px;
}

section.reviews .item .card .stars img {
  width: 150px;
  height: 20px;
  object-fit: contain;
  object-position: left;
}

section.reviews .item .card .img-info {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

section.reviews .item .card .img-info .img-box {
  flex: 0 0 70px;
  margin-right: 20px;
}

section.reviews .item .card .text {
  font-size: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

section.reviews .owl-nav button {
  width: 55px;
  height: 55px;
  border-radius: 50% !important;
  border: 2px solid #eccc43 !important;
  position: absolute;
  top: 50%;
  transition: 0.3s;
}

section.reviews .owl-nav button:hover {
  background: #eccc43 !important;
}

section.reviews .owl-nav button.owl-prev {
  transform: translate(0, -50%);
  left: -75px !important;
}

section.reviews .owl-nav button.owl-next {
  transform: translate(0, -50%);
  right: -75px !important;
}

@media (max-width: 1024px) {
  section.reviews .owl-nav button {
    transform: unset !important;
    position: unset !important;
    margin: 0 15px !important;
  }
}

section.reviews .owl-nav button img {
  object-fit: contain;
  width: 30px;
}

.header.header-secondary {
  position: static;
  background: #0e0e44;
  padding: 22px 0;
}

.header.header-secondary .header-inner .login-btns .signup-btn {
  font-family: 'AvinerLight';
  border-radius: 6px;
  padding: 0.5rem 1.9rem;
  margin: 0 20px;
  border: 1px solid #eccc43;
  color: #eccc43;
}

.header.header-secondary .header-inner .login-btns .signup-btn:hover {
  color: white;
  background: #eccc43;
}

.header.header-secondary .header-inner .login-btns .login-btn {
  font-family: 'AvinerLight';
  color: white;
  border-radius: 6px;
  padding: 0.5rem 1.9rem;
}

.footer-secondary .footer {
  margin: 0;
  padding-top: 30px;
}

section.pricing {
  padding: 70px 0;
  background: #fff;
}

@media (min-width: 1400px) {
  section.pricing .container {
    max-width: 1350px;
  }
}

section.pricing p {
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
}

section.pricing .title {
  text-align: center;
}

section.pricing .title h1 {
  font-size: 60px;
  line-height: 82px;
  font-family: 'Source Sans 3', sans-serif;
}

section.pricing .title h1 span {
  color: #eccc43;
}

section.pricing .title p {
  font-size: 19px;
  line-height: 30px;
  color: #444;
}

section.pricing .pricing-wrapper {
  margin-top: 100px;
}

@media only screen and (max-width: 991px) {
  section.pricing .pricing-wrapper {
    margin-top: 0;
  }
}

@media only screen and (max-width: 991px) {
  section.pricing .pricing-wrapper .row {
    gap: 70px;
  }
}

section.pricing .pricing-wrapper .price-card {
  border: 1px solid #e0e0e0;
  border-top: 3px solid #b2b2b2;
  border-right: 1px solid transparent;
  position: relative;
}

@media only screen and (max-width: 991px) {
  section.pricing .pricing-wrapper .price-card {
    border-right: 1px solid #e0e0e0;
  }
}

section.pricing .pricing-wrapper .price-card .price-header {
  padding: 60px 25px 25px;
  min-height: 183px;
}

@media only screen and (max-width: 767px) {
  section.pricing .pricing-wrapper .price-card .price-header {
    min-height: unset;
  }
}

section.pricing .pricing-wrapper .price-card .price-header .grey-logo {
  position: absolute;
  left: 50%;
  top: -40px;
  transform: translate(-50%, 0);
}

section.pricing .pricing-wrapper .price-card .price-header .red-strike {
  position: relative;
  font-size: 18px;
  max-width: 180px;
  margin: auto;
}

section.pricing .pricing-wrapper .price-card .price-header .red-strike::before {
  content: '';
  position: absolute;
  width: 115px;
  height: 5px;
  background: #ff0000;
  left: 0;
  border-radius: 6px;
  top: 50%;
  transform: translate(0, -50%);
}

section.pricing .pricing-wrapper .price-card .price-header img {
  height: 75px;
  width: 75px;
  object-fit: contain;
  border: 5px solid white;
  border-radius: 50%;
}

section.pricing .pricing-wrapper .price-card .price-header h5 {
  font-size: 24px;
  line-height: 35px;
  font-family: 'Source Sans 3', sans-serif;
}

section.pricing .pricing-wrapper .price-card .price-header p {
  color: #999999;
}

section.pricing .pricing-wrapper .price-card .price-header strong {
  color: black;
  font-family: 'Source Sans 3', sans-serif;
  font-size: 24px;
}

section.pricing .pricing-wrapper .price-card .checkboxes {
  text-align: center;
}

section.pricing .pricing-wrapper .price-card .checkboxes .checkbox-each {
  padding: 0 25px;
  line-height: 55px;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 991px) {
  section.pricing .pricing-wrapper .price-card .checkboxes .checkbox-each {
    justify-content: flex-start;
  }

  section.pricing
    .pricing-wrapper
    .price-card
    .checkboxes
    .checkbox-each
    .svg-icon {
    margin-right: 15px;
  }
}

section.pricing
  .pricing-wrapper
  .price-card
  .checkboxes
  .checkbox-each:nth-child(odd) {
  background: #f9f9f9;
}

section.pricing .pricing-wrapper .price-card .checkboxes .checkbox-each .text {
  display: none;
  font-size: 15px;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: bold;
  color: #555;
}

@media only screen and (max-width: 991px) {
  section.pricing
    .pricing-wrapper
    .price-card
    .checkboxes
    .checkbox-each
    .text {
    display: block;
    line-height: 20px;
    text-align: left;
  }
}

section.pricing .pricing-wrapper .price-card .checkboxes .subscribe-btn {
  padding: 45px 20px;
  min-height: 145px !important;
  justify-content: center;
}

section.pricing .pricing-wrapper .price-card .checkboxes .subscribe-btn .btn {
  width: 100%;
  max-width: 225px;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 8px;
  color: white;
  font-size: 20px;
  border: 1px solid #eccc43;
}

@media only screen and (max-width: 767px) {
  section.pricing .pricing-wrapper .price-card .checkboxes .subscribe-btn .btn {
    max-width: 100%;
  }
}

section.pricing
  .pricing-wrapper
  .price-card
  .checkboxes
  .subscribe-btn
  .outline-btn {
  color: #eccc43;
}

section.pricing
  .pricing-wrapper
  .price-card
  .checkboxes
  .subscribe-btn
  .outline-btn:hover {
  background: #eccc43;
  color: white;
}

section.pricing .pricing-wrapper .price-card.last {
  border-right: 2px solid #e0e0e0;
}

section.pricing .pricing-wrapper .price-card.last .red-strike::before {
  background: transparent;
}

section.pricing .pricing-wrapper .price-card.first {
  border: none;
}

@media only screen and (max-width: 991px) {
  section.pricing .pricing-wrapper .price-card.first {
    display: none;
  }
}

section.pricing .pricing-wrapper .price-card.first .price-header {
  visibility: hidden;
  opacity: 0;
  min-height: 184px;
}

section.pricing .pricing-wrapper .price-card.first .checkboxes.lists {
  border: 1px solid #e0e0e0;
  text-align: left;
  border-right: none;
}

section.pricing
  .pricing-wrapper
  .price-card.first
  .checkboxes.lists
  .checkbox-each {
  line-height: 22px;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

section.pricing .pricing-wrapper .price-card.first .checkboxes.lists .text {
  font-size: 15px;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: bold;
  color: #555;
  display: block;
}

section.pricing .cta {
  margin-top: 90px;
  margin-bottom: 30px;
  padding: 65px 15px 40px;
  border: 1px solid #e0e0e0;
  position: relative;
  background: url(../img/cta-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

section.pricing .cta .cta-logo {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: -45px;
  border: 4px solid white;
}

section.pricing .cta .cta-logo img {
  width: 75px;
  height: 75px;
  object-fit: contain;
}

section.pricing .cta .cta-content h4 {
  font-size: 31px;
  font-family: 'Source Sans 3', sans-serif;
  line-height: 35px;
}

@media only screen and (max-width: 991px) {
  section.pricing .cta .cta-content h4 {
    font-size: 24px;
    line-height: 30px;
  }
}

section.pricing .cta .cta-content .price {
  margin: 10px 0 15px;
  font-size: 18px;
}

section.pricing .cta .cta-content .price strong {
  font-size: 31px;
  font-family: 'Source Sans 3', sans-serif;
}

@media only screen and (max-width: 991px) {
  section.pricing .cta .cta-content .price strong {
    font-size: 24px;
  }
}

section.pricing .cta .cta-btn .btn {
  width: 100%;
  max-width: 225px;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 8px;
  color: white;
  font-size: 20px;
  border: 1px solid #eccc43;
  color: #eccc43;
}

section.pricing .cta .cta-btn .btn:hover {
  color: white;
  background: #eccc43;
}

.team-banner {
  min-height: 400px;
  background: url(../img/team-banner.png);
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .team-banner {
    min-height: 250px;
  }
}

.team-banner h1 {
  color: white;
  font-size: 76px;
  line-height: 82px;
  font-family: 'Source Sans 3', sans-serif;
  margin-bottom: 10px;
}

@media only screen and (max-width: 991px) {
  .team-banner h1 {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 0;
  }
}

.team-banner h1 span {
  color: #eccc43;
}

.team-banner p {
  color: white;
  font-size: 21px;
  line-height: 30px;
  max-width: 415px;
}

.team {
  padding: 75px 0 50px;
}

@media (min-width: 1400px) {
  .team .container {
    max-width: 1220px;
  }
}

.team .column {
  position: relative;
}

.team .content {
  margin-bottom: 60px;
}

.team .content p {
  font-family: 'Source Sans 3', sans-serif;
  font-size: 19px;
  color: #444;
}

.team .team-cards .team-each-wrap {
  min-height: 349px;
  margin-bottom: 40px;
}

.team .team-cards .team-each {
  padding: 30px 20px 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 10px;
  border-bottom: 3px solid #eccc43;
  background: white;
  position: absolute;
  width: calc(100% - 3rem);
}

@media only screen and (max-width: 767px) {
  .team .team-cards .team-each {
    width: calc(100% - 1.5rem);
  }
}

.team .team-cards .team-each .team-img {
  height: 180px;
  width: 180px;
  border-radius: 50%;
  margin: auto;
  position: relative;
}

.team .team-cards .team-each .team-img .profile {
  height: 180px;
  width: 180px;
  object-fit: cover;
  border-radius: 50%;
}

.team .team-cards .team-each .team-img .social-icon {
  height: 40px;
  position: absolute;
  right: 12px;
  bottom: 0;
  width: 40px;
  object-fit: contain;
  border-radius: 50%;
  background-color: #fff;
  border: 3px solid white;
}

.team .team-cards .team-each .team-info {
  margin-bottom: 15px;
}

.team .team-cards .team-each .team-info h3 {
  margin-top: 25px;
  margin-bottom: 5px;
  font-size: 30px;
  font-family: 'Source Sans 3', sans-serif;
}

.team .team-cards .team-each .team-info .designation {
  font-size: 27px;
  font-family: 'Source Sans 3', sans-serif;
  color: #666;
}

.team .team-cards .team-each .team-desc {
  max-height: 0px;
  overflow: hidden;
  transition: all 0.4s;
}

.team .team-cards .team-each .team-desc p {
  font-family: 'Source Sans 3', sans-serif;
  color: #666;
  font-size: 17px;
  line-height: 25px;
  margin-bottom: 20px;
}

.team .team-cards .team-each:hover {
  z-index: 2;
}

.team .team-cards .team-each:hover .team-desc {
  max-height: 75px;
}
.material-symbols-rounded {
  font-variation-settings: 'FILL' 1, 'wght' 900, 'GRAD' 1, 'opsz' 48;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 48;
}
