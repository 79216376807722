.faq-banner {
  min-height: 400px;
  background: #162630;
  display: flex;
  align-items: center;
  h1 {
    color: white;
    font-size: 76px;
    line-height: 82px;
    font-family: 'Source Sans 3', sans-serif;
    margin-bottom: 10px;
    font-weight: bold;
    @media only screen and (max-width: 991px) {
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 0;
    }
    span {
      color: #eccc43;
    }
  }
  p {
    color: white;
    font-size: 26px !important;
    line-height: 30px;
    max-width: 450px;
    margin-bottom: 0;
    @media only screen and (min-width: 991px) {
      font-size: 36px !important;
      line-height: 40px;
    }
  }
  .player-image {
    display: none;
    background: url(../img/fielder.png);
    width: 40vw;
    height: 400px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 100px;
    right: 0px;
    @media only screen and (min-width: 576px) {
      display: block;
    }
    @media only screen and (min-width: 992px) {
      right: 200px;
    }
  }
  @media only screen and (max-width: 991px) {
    min-height: 250px;
  }
}

section.questions {
  padding: 120px 0;
  .question-cards {
    .card {
      border: none;
      .card-header {
        margin-bottom: 2px;
        border: none;
        background-color: #efefef;
        cursor: pointer;
        font-family: 'AvinerHeavy';
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .card-body {
        background-color: #fafafa;
        .card-content {
          display: flex;
          flex-direction: column;
          gap: 20px;
          @media only screen and (min-width: 992px) {
            flex-direction: row;
            .card-content-left {
              flex-basis: 38.2%;
            }
            .card-content-right {
              flex-basis: 62.7%;
            }
          }
        }
      }
      &.expanded,
      &:hover {
        .card-header {
          border-bottom: 2px solid #ccc;
          margin-bottom: 0px;
        }
      }
    }
  }
}
