.invite-users-modal-content-wrapper {
  margin: 0 !important;
  flex-wrap: wrap;
  .invite-users-modal-content-choose-team-col,
  .invite-users-modal-content-quiz-details-col {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    .modal-body {
      display: flex;
      flex-direction: column;
    }
  }
  .quiz-invite-tab-wrapper {
    padding: 30px;
    border: 1px solid grey;
    background-color: #efefef;
    display: flex;
    flex-direction: column;
    .tab-content {
      overflow: scroll;
    }
    .tab-content,
    .quiz-invite-team-table-header {
      background: white;
    }
    &,
    .tab-content,
    li.nav-item > a {
      border-radius: 15px;
    }
    li.nav-item > a {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .quiz-invite-groups {
      width: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 14px;
      gap: 10px 20px;
      overflow-x: scroll;
      button.quiz-invite-group-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        flex-shrink: 0;
        padding: 8px;
        & > svg {
          margin-top: -3px;
        }
      }
    }
    .quiz-invite-team-table-header {
      display: flex;
      border-top: 1px solid grey;
      position: sticky;
      top: 0;
      z-index: 2;
      .quiz-invite-all-team-checkbox {
        width: 50px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        padding: 12px;
        cursor: pointer;
      }
    }
  }

  .invite-users-modal-content-quiz-details-col {
    order: -1;
    .modal-body {
      align-items: center;
      .form-group {
        width: 100%;
        margin-bottom: 3rem;
        label {
          width: 100%;
          text-align: center;
          font-size: 40px;
          font-weight: 900;
        }
        input {
          width: calc(100% - 80px);
          margin: 0px auto;
          &[type='text'] {
            font-size: 2rem;
            padding: 10px;
            border-radius: 15px;
            text-align: center;
          }
        }
        .input-group {
          .number-of-questions-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0px;
            width: 100%;
            .number-of-questions-slider-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              gap: 4px;
              width: 100%;
              input[type='range'] {
                margin-top: -4px;
                cursor: pointer;
              }
              span {
                opacity: 0.35;
                font-size: 1.2em;
              }
            }
          }
        }
      }
      p.quiz-summary-paragraph {
        min-height: 128px;
        overflow-y: scroll;
        height: auto;
        max-width: 100%;
        text-wrap: wrap;
        max-height: 200px;
      }
    }
  }
  .summary-main-col {
    display: block;
  }
  .summary-right-side {
    display: none;
  }
  @media (min-width: 1210px) {
    .summary-main-col {
      display: none;
    }
    .summary-right-side {
      display: block;
    }
    &.auto-quiz {
      .invite-users-modal-content-choose-team-col {
        flex: 0 0 66%;
      }
      .invite-users-modal-content-quiz-details-col {
        flex: 0 0 33%;
        order: 1;
      }
    }
    &.re-invite {
      .invite-users-modal-content-choose-team-col {
        flex: 0 0 100%;
      }
      .invite-users-modal-content-quiz-details-col {
        flex: 0 0 0%;
        order: 1;
      }
    }
  }
  .schedule-choose-day-column {
    padding: 0 5px;
    button {
      width: 100%;
      padding: 30px 0;
      color: black;
    }
  }
  .schedule-choose-time-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .schedule-choose-time-slider-wrapper {
      width: 100%;
      display: flex;
      input[type='range'] {
        flex: 1;
        -webkit-appearance: none;
        width: 100%;
        height: 6px;
        top: 12px;
        position: relative;
        background: linear-gradient(
          to right,
          #efefef,
          #efefef
        ); /* Set the background color */
        outline: none;

        /* Style the thumb (handle) of the range input */
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 20px;
          height: 20px;
          background: #000; /* Set the color of the thumb */
          border-radius: 50%;
          cursor: pointer;
          position: relative;
          top: -6px;
        }

        /* Style the track (bar) of the range input */
        &::-webkit-slider-runnable-track {
          width: 100%;
          height: 5px;
          cursor: pointer;
          background: transparent; /* Set the color of the track */
        }

        /* Style the progress of the range input */
        &::-webkit-slider-thumb::-moz-range-progress {
          background-color: red; /* Set the color of the progress */
        }
      }
    }
  }
}

.quiz-invite-tab-wrapper {
  height: calc(100vh - 300px);
  overflow: hidden;
}
.group-invite-table-wrapper {
  overflow-y: scroll;
  max-height: calc(100vh - 480px);
}

.group-invite-table-wrapper table tr .show-on-hover {
  display: none;
}
.group-invite-table-wrapper table tr:hover .show-on-hover {
  display: inherit;
}
