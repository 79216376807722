.profile-view {
  .field-edit-button {
    display: inline;
    position: absolute;
    right: 10px;
    top: 0;
    height: 53px;
    line-height: 53px;
    z-index: 10;
    cursor: pointer;
  }

  .profile-view-tabs {
    .nav-item {
      a {
        font-family: 'AvinerHeavy';
        font-size: 0.8em;
        font-weight: 900;
        display: inline-block;
        &.active,
        &:hover {
          border-left: 2px solid #162630;
          padding-left: 14px;
        }
        &:hover {
          color: #162630 !important;
        }
        &:not(.active):not(:hover) {
          padding-left: 16px;
        }
      }
    }
  }
  .profile-save-button {
    margin-top: 1rem !important;
    float: right;
  }
}
