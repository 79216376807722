.btn-wrap {
  .btn {
    &.secondary-btn {
      color: white !important;
      display: inline-flex;
      font-size: 18px;
      font-weight: lighter;
      background: #08081c;
      font-family: 'AvinerHeavy';
      border-radius: 50px;
      padding: 0.5rem 2rem;
      outline: none !important;
      box-shadow: none !important;
      align-items: center;
      &:hover {
        background: #131344;
        color: white;
      }
    }
  }
}
