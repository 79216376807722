.signup-choose-user-type-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  @media only screen and (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
  a {
    .card {
      padding: 20px;
      text-align: center;
      height: 100%;
      .card-body {
        font-family: 'AvinerHeavy';
        font-size: 1.5rem;
        line-height: 1.7rem;
        padding: 1.25rem 0 0;
      }
    }
    &:hover {
      .card {
        background-color: #efefef;
        .card-header {
          background-color: #efefef;
        }
      }
    }
  }
}

.signup-header {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  .signup-back-button {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    svg {
      margin-top: -3px;
    }
  }
  @media only screen and (min-width: 992px) {
    grid-template-columns: 80px 1fr 80px;
  }
}

.redirect-player-content-section {
  grid-template-columns: 1fr;
  display: grid;
  align-items: center;
  .redirect-player-content p {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  img {
    height: 200px;
    object-fit: contain;
  }
  @media only screen and (min-width: 992px) {
    grid-template-columns: 38.7% 61.2%;
    img {
      height: auto;
    }
    .redirect-player-content p {
      font-size: 2rem;
      line-height: 2.3rem;
    }
  }
}

.signup-create-account-section {
  &.friendly {
    grid-template-columns: 1fr;
  }
  &.inactive-user-renew {
    grid-template-columns: 50% 1px 1fr;
  }
  display: grid;
  grid-template-columns: 1fr;
  @media only screen and (min-width: 992px) {
    grid-template-columns: 62% 1px 1fr;
  }
  gap: 20px;
  margin-bottom: 1rem;
  .signup-create-account-separator {
    display: none;
    @media only screen and (min-width: 992px) {
      display: block;
    }
    background-color: #efefef;
    margin: 20px 0;
  }
  .signup-create-account-choose-quantity {
    display: flex;
    flex-direction: column;
  }

  .signup-create-account-choose-quantity {
    .signup-create-account-cost,
    .signup-create-account-description,
    .signup-create-account-team-description,
    .signup-create-account-total-cost,
    .signup-create-account-suggested-cost,
    .signup-create-account-savings-cost {
      text-align: center;
    }
    .signup-create-account-team-description {
      font-size: 0.8rem;
      margin-top: -4px;
    }

    .signup-create-account-savings-cost {
      color: green;
    }
    .signup-create-account-sale-reason {
      @media only screen and (min-width: 992px) {
        margin-top: 3rem;
        margin-bottom: -3rem;
      }
      color: red;
      font-weight: 900;
      text-align: center;
    }
    .signup-create-account-suggested-cost {
      @media only screen and (min-width: 992px) {
        margin-top: 3rem;
      }
      color: red;
      text-decoration: line-through;
    }
    .signup-create-account-cost,
    .signup-create-account-suggested-cost {
      font-size: 60px;
      line-height: 60px;
    }
    .signup-create-account-suggested-cost,
    .signup-create-account-savings-cost {
      visibility: hidden;
      &.visible {
        visibility: visible;
      }
    }
  }
  .signup-create-account-join-existing-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 38.2%;
    gap: 60px;
    padding-left: 20px;
    & > div {
      width: 100%;
    }
  }
}

.signup-create-free-account-section {
  &.inactive-user-renew {
    grid-template-columns: 50% 1px 1fr;
  }
  display: grid;
  grid-template-columns: 1fr;
  @media only screen and (min-width: 992px) {
    grid-template-columns: 1fr;
  }
  gap: 20px;
  margin-bottom: 1rem;
  .signup-create-account-separator {
    display: none;
    @media only screen and (min-width: 992px) {
      display: block;
    }
    background-color: #efefef;
    margin: 20px 0;
  }
  .signup-create-account-choose-quantity {
    display: flex;
    flex-direction: column;
  }

  .signup-create-account-choose-quantity {
    .signup-create-account-cost,
    .signup-create-account-description,
    .signup-create-account-team-description,
    .signup-create-account-total-cost,
    .signup-create-account-suggested-cost,
    .signup-create-account-savings-cost {
      text-align: center;
    }
    .signup-create-account-team-description {
      font-size: 0.8rem;
      margin-top: -4px;
    }

    .signup-create-account-savings-cost {
      color: green;
    }
    .signup-create-account-sale-reason {
      @media only screen and (min-width: 992px) {
        margin-top: 3rem;
        margin-bottom: -3rem;
      }
      color: red;
      font-weight: 900;
      text-align: center;
    }
    .signup-create-account-suggested-cost {
      @media only screen and (min-width: 992px) {
        margin-top: 3rem;
      }
      color: red;
      text-decoration: line-through;
    }
    .signup-create-account-cost,
    .signup-create-account-suggested-cost {
      font-size: 60px;
      line-height: 60px;
    }
    .signup-create-account-suggested-cost,
    .signup-create-account-savings-cost {
      visibility: hidden;
      &.visible {
        visibility: visible;
      }
    }
  }
}

.signup-create-account-choose-team {
  display: flex;
  flex-direction: column;
  .create-new-access-segmented-control {
    margin-bottom: 21px;
    button.btn {
      background-color: #efefef;
      &.active {
        background-color: #f6d30d;
        color: #fff;
      }
      &:not(.active):hover {
        background-color: #ddd;
      }
    }
    & {
    }
  }
}

.signup-create-account-suggested-teams {
  display: flex;
  flex-direction: column;
  .suggested-team {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem;
    border-radius: 0.625rem;
    margin-bottom: 10px;

    &.selected {
      background-color: rgba(0, 255, 0, 0.2);
      border: 1px solid green;
      .suggested-team-details > div {
        color: green;
        font-family: 'AvinerHeavy';
      }
    }
    &:hover {
      svg {
        visibility: visible;
      }
    }
    svg {
      visibility: hidden;
      margin-top: -3px;
      fill: #666666;
      &.suggested-team-remove-selected {
        fill: red;
        cursor: pointer;
      }
    }
    .suggested-team-details {
      font-family: 'AvinerHeavy';
      color: #666666;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
}
.selected-team-error-message {
  & > div {
    color: red;
    font-family: 'AvinerHeavy';
  }
  ul {
    padding-left: 0;
    margin: 0;
    li {
      font-size: 1rem;
      line-height: 1.2rem;
      margin-bottom: 4px;
      color: red;
    }
  }
}
.signup-create-account-search-append {
  display: none;
  cursor: pointer;
  &.visible {
    display: flex;
  }
  &:hover {
    .input-group-text {
      background-color: #f6d30d;
      svg {
        fill: white;
      }
    }
  }
}
