.media-gallery {
  display: grid;
  width: 100%;
  align-items: center;
  justify-content: center;
  img,
  video {
    object-fit: contain;
    padding: 16px;
    cursor: pointer;
    &:hover {
      background: #efefef;
    }
  }
}
