.team-banner {
  min-height: 400px;
  background: #162630;
  display: flex;
  align-items: center;
  p {
    color: white;
    font-size: 26px !important;
    line-height: 30px;
    max-width: 415px;
    margin-bottom: 0;
    @media only screen and (min-width: 991px) {
      font-size: 36px !important;
      line-height: 40px;
    }
  }
  .player-image {
    display: none;
    background: url(../img/batter.png);
    width: 40vw;
    height: 400px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 100px;
    right: 0px;
    @media only screen and (min-width: 576px) {
      display: block;
    }
    @media only screen and (min-width: 992px) {
      right: 200px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .team-banner {
    min-height: 250px;
  }
}

.team-banner h1 {
  color: white;
  font-size: 76px;
  line-height: 82px;
  font-family: 'Source Sans 3', sans-serif;
  margin-bottom: 10px;
  font-weight: bold;
}

@media only screen and (max-width: 991px) {
  .team-banner h1 {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 0;
  }
}

.team-banner h1 span {
  color: #eccc43;
}

.team {
  padding: 75px 0 50px;
}

@media (min-width: 1400px) {
  .team .container {
    max-width: 1220px;
  }
}

.team .column {
  position: relative;
}

.team .content {
  margin-bottom: 60px;
}

.team .content p {
  font-family: 'Source Sans 3', sans-serif;
  font-size: 19px;
  color: #444;
}

.team .team-cards .team-each-wrap {
  min-height: 349px;
  margin-bottom: 40px;
  min-height: 432px;
}

.team .team-cards .team-each {
  padding: 30px 20px 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 10px;
  border-bottom: 3px solid #eccc43;
  background: white;
  position: absolute;
  width: calc(100% - 3rem);
  min-height: 432px;
  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    border-bottom: 6px solid #eccc43;
  }
}

@media only screen and (max-width: 767px) {
  .team .team-cards .team-each {
    width: calc(100% - 1.5rem);
  }
}

.team .team-cards .team-each .team-img {
  height: 180px;
  width: 180px;
  border-radius: 50%;
  margin: auto;
  position: relative;
}

.team .team-cards .team-each .team-img .profile {
  height: 180px;
  width: 180px;
  object-fit: cover;
  border-radius: 50%;
}

.team .team-cards .team-each .team-img .social-icon {
  height: 40px;
  position: absolute;
  right: 12px;
  bottom: 0;
  width: 40px;
  object-fit: contain;
  border-radius: 50%;
  background-color: #fff;
  border: 3px solid white;
}

.team .team-cards .team-each .team-info {
  margin-bottom: 15px;
}

.team .team-cards .team-each .team-info h3 {
  margin-top: 25px;
  margin-bottom: 5px;
  font-size: 30px;
  font-family: 'Source Sans 3', sans-serif;
}

.team .team-cards .team-each .team-info .designation {
  font-size: 27px;
  font-family: 'Source Sans 3', sans-serif;
  color: #666;
}

.team .team-cards .team-each .team-desc {
  max-height: 0px;
  overflow: hidden;
  transition: all 0.4s;
}

.team .team-cards .team-each .team-desc p {
  font-family: 'Source Sans 3', sans-serif;
  color: #666;
  font-size: 17px;
  line-height: 25px;
  margin-bottom: 20px;
}

.team .team-cards .team-each:hover {
  z-index: 2;
}

.team .team-cards .team-each:hover .team-desc {
  max-height: 75px;
}

section.team {
  .team-cards {
    .team-each {
      cursor: pointer;
      .team-view-bio {
        font-weight: bolder;
        color: #212529;
        font-size: 1rem;
        line-height: 1.7;
      }
    }
  }
}

.modal.team-bio-details-modal {
  h3 {
    margin-top: 25px;
    margin-bottom: 5px;
    font-size: 30px;
    font-family: 'Source Sans 3', sans-serif;
  }
  h4 {
    font-size: 27px;
    font-family: 'Source Sans 3', sans-serif;
    color: #666;
  }
  .team-bio-text {
    font-family: 'Source Sans 3', sans-serif;
    font-size: 19px;
    color: #444;
  }

  .image-wrapper.column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    img {
      object-fit: contain;
      aspect-ratio: 1;
      width: auto;
      height: 200px;
      align-self: center;
      @media (min-width: 992px) {
        width: 100%;
        height: auto;
        align-self: flex-start;
      }
    }
  }
  .modal-close-button {
    padding: 20px;
    cursor: pointer;
    position: absolute;
    z-index: 100;
    right: -10px;
    top: -10px;
    @media (min-width: 992px) {
      right: -40px;
      top: -40px;
    }
    .modal-close-button-background {
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: -1;
      width: 50px;
      height: 50px;
      display: block;
      background: white;
      border-radius: 50%;
    }
    svg {
      z-index: 2;
    }
    // &::before {
    //   width: 40px;
    //   height: 40px;
    //   border-radius: 50%;
    //   background-color: white;
    //   content: ' ';
    //   display: block;
    //   position: relative;
    //   top: -40px;
    //   z-index: 1;
    // }
  }
}
