.question-list-view-group-left {
  .question-list-view.list-group-item {
    border: 1px solid rgba(0, 0, 0, 0.08);
    position: relative;
    &:hover,
    &.expanded {
      border: 1px solid #333;
    }
    .item-action-buttons {
      position: absolute;
      right: 0;
      top: -5px;
      padding: 0 32px 0 0;

      gap: 0;
      transition: transform 0.5s ease-in-out;
      transform: translateX(calc(100% - 60px));
      &:hover {
        transform: translateX(0);
        .item-action-buttons-more-button-wrapper {
          opacity: 0;
        }
      }
      a,
      .item-action-buttons-more-button-wrapper {
        padding: 0.375rem 8px;
      }
      .item-action-buttons-more-button-wrapper {
        background-color: transparent;
        margin-right: 40px;
        transition: opacity 0.5s ease-in-out;
      }

      svg.more-button {
        padding-left: 1.25rem;
      }
      a {
        background: rgba(239, 239, 239, 0.85);
        &:hover {
          background-color: #f6d30d;
          color: #162630 !important;
        }
      }
    }
    &.expanded {
      .item-action-buttons {
        background: #efefef;
        top: -50px;
        right: -16px;
        border: 1px solid #333;
        border-bottom: none;
      }
    }
  }
}
