.super-admin-impersonate-wrapper {
  margin: 40px 0 80px;
  table {
    tbody {
      tr {
        td {
          padding: 2px 0;
          font-size: 14px;
          &:first-child {
            padding-left: 10px;
          }
        }
      }
    }
  }
}
