.player-update-team-wrapper {
  margin: 1.25rem -1.25rem;
  padding: 60px 1.25rem 1.25rem;
  .player-update-team-header {
    display: flex;
    align-items: center;
    h2 {
      margin: 0;
    }
  }
  .team-error-message {
    display: none;
  }
  &.error {
    h2 {
      color: red;
    }
    background: rgba(255, 0, 0, 0.1);
    .team-error-message {
      display: block;
      font-family: 'AvinerHeavy';
      color: red;
    }
  }
}
.player-team-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .player-team-list-item-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;

    .team-maxxed-out-message {
      font-size: 0.6em;
      font-weight: 800;
    }

    &.maxxed:not(.selected) {
      opacity: 0.35;
      background-color: #aaa;
    }

    &.selected {
      background-color: #efefef;
    }

    .player-update-team-list-item {
      min-height: 50px;
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;
      .player-update-team-checkbox {
        width: 20px;
      }
      .player-update-team-card-header {
        width: 300px;
        max-width: 25vw;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
      }
      .player-update-team-select {
        flex: 1;
      }
    }
  }
}
.player-update-team-card-deck {
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
  .player-update-team-card {
    cursor: pointer;
    .player-update-team-card-body {
      display: flex;
      flex-direction: column;
      gap: 14px;
      padding-bottom: 0px;

      .player-update-team-card-message,
      .player-update-team-card-message-hover {
        font-size: 0.6em;
        font-weight: 900;
        text-align: center;
      }
      .player-update-team-card-message {
        opacity: 0.3;
      }
      .player-update-team-card-selected-check {
        display: none;
      }

      .player-update-team-card-message-hover {
        display: none;
      }
    }
    &:hover {
      background-color: #fafafa;
      .player-update-team-card-message {
        display: none;
      }
      .player-update-team-card-message-hover {
        display: block;
      }
      .player-update-team-card-selected-check {
        display: block;
        opacity: 0.5;
      }
    }
    &.selected {
      background-color: #efefef;
      .player-update-team-card-selected-check {
        display: block;
        opacity: 1;
      }
      .player-update-team-card-message {
        opacity: 1;
      }
    }
  }
}
.player-update-error-label {
  font-weight: 900;
  color: red;
  display: block;
  margin-bottom: 0px;
  margin-top: -10px;
}
