.subscription-details-invoices-section {
  display: flex;
  flex-direction: column;
  .subscription-details-invoices-grid {
    display: grid;
    grid-template-columns: repeat(3, max-content) 1fr repeat(4, max-content);
    gap: 4px 10px;
    & > * {
      font-size: 14px;
    }
  }
}
