.coach-team-view-org-details-plan {
  flex: 1;
  margin-bottom: 30px;
  .card {
    &:hover {
      background-color: #fafafa;
      a {
        color: #f6d30d !important;
        font-weight: 900;
      }
    }
  }
  .coach-team-view-org-details-plan-grid {
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0px 10px;
    line-height: 1.382;
    & > :nth-child(2n + 1) {
      font-weight: bold;
    }
    & > :nth-child(2n) {
      text-align: right;
    }
  }
}
