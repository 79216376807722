.coach-team-view {
  .coach-team-view-org-section {
    margin-bottom: 30px;
    &.showing-details {
      margin-bottom: 80px;
      .coach-team-view-org-wrapper {
        background-color: #efefef;
      }
    }
    .coach-team-view-org-wrapper {
      padding: 34px;

      .coach-team-view-org-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        a {
          font-weight: bolder;
        }
      }
      .coach-team-view-org-details-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .coach-team-view-org-details-roster {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 10px;
          align-items: center;
          flex: 1.618;
          .coach-team-view-org-details-roster-wrapper {
            & > div,
            & > a {
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .coach-team-view-org-details-roster-badge {
                position: absolute;
                left: 60%;
                top: -8px;
              }
            }
          }
        }
      }
    }
  }
  .choose-team-card {
    height: 100%;
    cursor: pointer;
    &.selected {
      background-color: #efefef;
      &::after {
        content: 'current team';
        text-align: center;
        font-size: 0.8em;
        font-weight: bolder;
        color: #0e0e44;
        border-bottom: 2px solid #0e0e44;
        margin-bottom: -2px;
      }
      .team-card-edit-team-link {
        display: block;
      }
    }
    .team-card-edit-team-link {
      display: none;
    }
    &::after {
      content: '\00a0';
      text-align: center;
      font-size: 0.8em;
      font-weight: bolder;
      color: #0e0e44;
    }
    &:hover {
      background-color: #efefef;
      .card-header {
        opacity: 0.5;
      }
      &.selectable:not(.selected):after {
        content: 'switch to this team';
      }
      .team-card-edit-team-link {
        display: block;
      }
    }
    .team-card-header {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      h3 {
        line-height: 1;
        margin-bottom: 0;
      }
      .age-group {
        font-weight: bold;
        margin-top: 0;
      }
      img {
        height: 65px;
        width: 65px;
        object-fit: contain;
      }
    }
    .team-details {
      margin-top: 30px;
      & > div {
        line-height: 1.3;
      }
    }
  }
}

.team-player-requests-page {
  table.team-player-requests-table {
    .team-player-requests-action-buttons {
      font-family: 'AvinerHeavy';
      font-size: 1rem;
      padding: 0.375rem 1.125rem;
      margin: 2px 10px;
    }
  }
}

table {
  .requests-action-buttons {
    font-family: 'AvinerHeavy';
    font-size: 1rem;
    padding: 0.375rem 1.125rem;
    margin: 2px 10px;
  }
  & > tbody > tr:hover {
    .requests-action-buttons {
    }
  }
}
