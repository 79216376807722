#logos {
  &.logos {
    padding: 180px 0 180px;
    .logo-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      img {
        width: 210px;
        max-width: 33%;
      }
    }
  }
}
