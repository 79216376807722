table.quiz-table {
  tr {
    &:hover {
      td {
        .show-on-hover {
          display: block;
        }
      }
    }
    td {
      .show-on-hover {
        display: none;
      }
    }
  }
}
