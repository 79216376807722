section.why-6-tool {
  padding: 85px 0;
  background: #efefef;
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    background: url('../img/bg-pattern.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 40%;
    background-position-y: center;
  }
  @media only screen and (min-width: 992px) {
    background: url('../img/bg-pattern.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 70%;
    background-position-y: center;
  }
  h2 {
    font-size: 55px;
    line-height: 65px;
    font-family: 'Source Sans 3', sans-serif;
    color: #000;
    font-weight: bold;
  }
  p {
    margin-top: 20px;
    font-size: 18px;
    font-family: 'Source Sans 3', sans-serif;
    word-spacing: 2px;
    line-height: 1.5em;
    color: #000;
  }
}

section.why-banner {
  min-height: 400px;
  background: #162630;
  .player-image {
    display: none;
    background: url(../img/pitcher.png);
    width: 40vw;
    height: 400px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 280px;
    right: 0px;
    @media only screen and (min-width: 576px) {
      display: block;
    }
    @media only screen and (min-width: 768px) {
      top: 100px;
    }
    @media only screen and (min-width: 992px) {
      right: 200px;
    }
  }
  display: flex;
  align-items: center;
  h1 {
    color: white;
    font-size: 76px;
    line-height: 82px;
    font-family: 'Source Sans 3', sans-serif;
    margin-bottom: 10px;
    font-weight: bold;
    span {
      color: #eccc43;
    }
  }
  p {
    color: white;
    font-size: 26px !important;
    line-height: 30px;
    max-width: 415px;
    margin-bottom: 0;
    @media only screen and (min-width: 991px) {
      font-size: 36px !important;
      line-height: 40px;
    }
  }
}

section.how-it-works-example {
  padding: 200px 0;
}

.btn-wrap {
  .btn {
    &.secondary-btn {
      color: white !important;
      display: inline-flex;
      font-size: 18px;
      font-weight: lighter;
      background: #08081c;
      font-family: 'AvinerHeavy';
      border-radius: 50px;
      padding: 0.5rem 2rem;
      outline: none !important;
      box-shadow: none !important;
      align-items: center;
      &:hover {
        background: #131344;
        color: white;
      }
    }
  }
}
