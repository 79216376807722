@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  #header,
  .noprint,
  .footer,
  .breadcrumbs,
  header.card-header > a {
    display: none !important;
  }
  .printonly {
    display: block !important;
  }
  .showingQuestions.card {
    .quizPrintout {
      display: block !important;
      .quiz-questions-details-index {
        text-align: center;
      }
    }
    .playerResults {
      display: none !important;
    }
  }
  .showingResults.card {
    .quizPrintout {
      display: none !important;
    }
  }
  body.modal-open .quiz-questions-details-modal {
    display: none !important;
  }
  .modal-backdrop {
    display: none !important;
  }
}

@media screen {
  .showingQuestions.card {
  }

  .quizPrintout {
    display: none;
  }
  .printonly {
    display: none !important;
  }
}

.quiz-questions-details-modal {
  .modal-content {
    header.modal-header {
      flex-direction: column;
      align-items: center;
      padding-top: 0;
      h2 {
        align-self: flex-start;
      }

      .quiz-questions-details-modal-jump-section-content {
        display: flex;
        gap: 14px;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 900;
        font-family: 'AvinerHeavy';
      }
      .quiz-questions-details-modal-jump-index-link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        background: rgb(239, 239, 239);
        line-height: 36px;
        font-family: AvinerHeavy;
        font-weight: 900;
        font-size: 20px;
        padding-top: 2px;
        cursor: pointer;
        &.active,
        &:hover {
          background: #162630;
          color: white;
        }
        &.active {
          color: #f6d30d;
        }
      }
    }
    .quiz-questions-details-card {
      .quiz-questions-details-index {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        left: calc(50% - 25px);
        top: 25px;
        background: rgb(239, 239, 239);
        width: 50px;
        height: 50px;
        margin-bottom: 0px;
        z-index: 3;
        border-radius: 50%;
        font-family: AvinerHeavy;
        padding-top: 4px;
      }
      &.correct {
        .card {
          border: 2px solid green;
          background: rgba(0, 128, 0, 0.15);
          &.active {
            background-color: rgba(0, 128, 0, 0.3);
            box-shadow: 0px 0px 8px 2px rgba(0, 128, 0, 0.3);
          }
        }
        .quiz-questions-details-index {
          background: green;
          color: white;
        }
      }
      &.wrong {
        .card {
          border: 2px solid red;
          background: rgba(255, 0, 0, 0.15);
          &.active {
            background-color: rgba(255, 0, 0, 0.3);
            box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.3);
          }
        }
        .quiz-questions-details-index {
          background: red;
          color: white;
        }
      }
      .card {
        &.active {
          background-color: #fafafa;
          box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}

.quiz-invites-row {
  .quiz-results-invited-details {
    color: #ddd;
    font-style: italic;
    font-weight: 100;
    white-space: nowrap;
  }
  .hover-only {
    display: none;
  }
  &:hover .hover-only {
    display: block;
  }
}
