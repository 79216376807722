.mobile-question-view-content-wrapper .mobile-question-view-scroll {
  flex: 1;
  text-align: center;
  flex-direction: column;
  margin-top: 10px;
}

.mobile-question-view-content-wrapper .mobile-question-view-scroll-content {
  margin: 15px 0px 30px;
}

.mobile-question-view-content-wrapper .mobile-question-view-list {
  margin: 20px 0 0;
  flex: 1;
}

.mobile-question-view-content-wrapper .mobile-question-view-situation-wrapper {
  width: 100%;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  display: flex;
}

.mobile-question-view-content-wrapper
  .mobile-question-view-situation-wrapper.show-field.show-scoreboard {
  background: #222c58;
}

.mobile-question-view-content-wrapper
  .mobile-question-view-situation-wrapper
  > div:nth-child(2) {
  border-left: 1px solid #586496;
}

.mobile-question-view-content-wrapper
  .mobile-question-view-situation-wrapper
  > div {
  margin-bottom: 0;
}

.mobile-question-view-content-wrapper .mobile-question-view-field-wrapper {
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  border-right-color: rgba(255, 255, 255, 0.2);
  display: flex;
  /* border-right-width: ${props => props.scoreboard ? "1px" : "0px"};;
  background: ${props => props.scoreboard ? "#222c58" : "rgba(0,0,0,0)"}; */
}

.mobile-question-view-content-wrapper .mobile-question-view-list-item {
  background: #ffffff;
  margin-bottom: 16px;
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  min-height: 47px;
}

.mobile-question-view-content-wrapper .mobile-question-view-list-item.correct {
  display: flex;
}

.mobile-question-view-content-wrapper .mobile-question-view-text {
  color: #ffffff;
  font-family: 'AvinerHeavy';
  text-align: left;
  font-size: 16px;
  padding-right: 10px;
  flex: 1;
}

.mobile-question-view-content-wrapper .mobile-question-view-question-text {
  margin: 30px 0 10px;
}

.mobile-question-view-content-wrapper
  .mobile-question-view-list-item
  .mobile-question-view-text {
  color: #000000;
}

.mobile-question-view-content-wrapper .mobile-question-view-title {
  color: #ffffff;
  font-family: 'AvinerHeavy';
  margin-bottom: 8px;
  font-size: 18px;
}

.mobile-question-view-content-wrapper .mobile-question-view-position-text {
  color: #ffffff;
  font-family: 'AvinerHeavy';
  font-size: 14px;
  margin-top: -20px;
}

.mobile-question-view-content-wrapper .mobile-question-view-image {
  width: 100%;
  height: 220px;
  margin: 20px auto 10px;
  object-fit: contain;
}

.mobile-question-view-content-wrapper .mobile-question-view-timer-text {
  font-size: 20px;
  line-height: 36px;
  color: #ffffff;
  font-family: 'Aviner';
}

.mobile-question-view-content-wrapper
  .mobile-question-view-situation-position-text {
  color: #ffffff;
  font-size: 12px;
  text-align: center;
}

.mobile-question-view-content-wrapper .mobile-question-view-info-wrapper {
  width: 300px;
  border-radius: 37px;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
  background: #222c58;
}

.mobile-question-view-content-wrapper .mobile-question-view-info-content {
  flex: 1;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-color: transparent;
  padding-left: 0px;
  padding-right: 10px;
  display: flex;
}
.mobile-question-view-content-wrapper .mobile-question-view-info-content.last {
  border-left: 1px solid #586496;
}

.mobile-question-view-content-wrapper .mobile-question-view-info-info {
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.mobile-question-view-content-wrapper .mobile-question-view-info-strong {
  font-size: 14px;
  font-weight: 300;
  color: #ffff;
  font-family: 'Aviner';
  display: flex;
}
.mobile-question-view-content-wrapper .mobile-question-view-info-small {
  font-size: 18px;
  font-weight: 700;
  font-family: 'AvinerHeavy';
  text-align: center;
  color: #ffffff;
  display: flex;
}

.mobile-question-view-content-wrapper .mobile-question-view-progress-bar {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  margin-top: 16px;
  height: 6px;
  background: #00080d;
  margin: 16px 30px 30px;
}

.mobile-question-view-content-wrapper .mobile-question-view-progress-bar-done {
  position: absolute;
  top: 0;
  left: 0;
  height: 6px;
  width: 90%;
  background: #009336;
}

.mobile-question-view-content-wrapper .mobile-question-view-button {
  background: #e7c84f;
  margin-top: 36px;
  margin-bottom: 16px;
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  min-height: 47px;
  text-align: center;
}
