.custom-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ul.pagination {
    margin: 0;
    .page-item {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      margin: 0;
      overflow: hidden;
      border-radius: 0;
      border: 1px solid #dee2e6;
      border-right: none;
      font-family: 'AvinerHeavy';
      font-weight: 900;
      &.active {
        background-color: #eccc43;
        color: #ffffff;
        border: 1px solid #eccc43;
      }
      &:first-child {
        border-bottom-left-radius: 0.375rem;
        border-top-left-radius: 0.375rem;
      }
      &:last-child {
        border-bottom-right-radius: 0.375rem;
        border-top-right-radius: 0.375rem;
        border-right: 1px solid #dee2e6;
      }
      .page-link {
        padding: 0;
        border: none;
        background-color: transparent !important;
      }
      &:hover {
        &:not(.active) {
          background: rgb(239, 239, 239);
          .page-link {
            color: black !important;
          }
        }
      }
    }
  }
}
