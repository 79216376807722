$light-text-opacity: 0.5;
$heavy-font-family: 'AvinerHeavy';
$heavy-font-weight: 700;
$small-font-size: 13px;

:root {
  --light-text-opacity: 0.5;
  --heavy-font-family: 'AvinerHeavy';
  --heavy-font-weight: 700;
  --regular-font-family: 'AvinerLight';
  --regular-font-weight: 400;
  --small-font-size: 13px;
}

.subscription-modal {
  .subscription-pricing-table-fader {
    transition: opacity 1s ease-in-out;
  }
  .subscription-pricing-table-wrapper {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, calc(33.333% - 13.34px));
    gap: 20px;
    .subscription-pricing-table-item {
      padding: 20px;
      border-radius: 10px;
      flex: 1;
      &.disabled {
        opacity: 0.35;
      }
      &:not(.disabled):hover {
        background-color: #f6f6f6;
      }

      .subscription-pricing-table-title {
        font-weight: var(--heavy-font-weight);
        font-family: var(--heavy-font-family);
      }
      .subscription-pricing-table-start-at {
        opacity: var(--light-text-opacity);
        font-size: var(--small-font-size);
        height: 26px;
        display: table-cell;
        vertical-align: bottom;
        line-height: 1;
      }
      .subscription-pricing-table-price-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 4px;
        .subscription-pricing-table-price-amount {
          font-size: 36px;
          line-height: 1;
          font-weight: var(--heavy-font-weight);
          font-family: var(--heavy-font-family);
        }
        .subscription-pricing-table-price-term {
          font-size: var(--small-font-size);
          line-height: 1;
          margin-bottom: 8px;
          opacity: var(--light-text-opacity);
          padding-left: 4px;
          padding-right: 4px;
        }
      }
      .subscription-pricing-table-subscripe-button-loader {
        margin: 14px 0;
        padding: 0.75rem 1rem;
        font-family: var(--heavy-font-family);
        font-size: 20px;
        font-weight: 500;
        width: 100%;
        text-align: center;
      }
      .subscription-pricing-table-subscribe-button {
        width: 100%;
        color: black !important;
        font-weight: 500;
        font-family: var(--heavy-font-family);
        font-size: 20px;
        margin: 14px 0;
        &:hover {
          color: white !important;
        }
        &.current-plan {
          &.btn-outline-primary {
            &:hover {
              background-color: transparent !important;
              cursor: no-drop;
              color: black !important;
            }
          }
        }
      }
      .subscription-pricing-table-features-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        .subscription-pricing-table-features-header,
        .subscription-pricing-table-features-list-item
          .subscription-pricing-table-features-list-item-text {
          font-size: 16px;
        }
        .subscription-pricing-table-features-list-wrapper {
          margin: 0px -8px;
          .subscription-pricing-table-features-list-item {
            padding: 0px 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 12px;
            &.access-highlighted {
              background-color: #e6e6e6;
              border-radius: 4px;
              .subscription-pricing-table-features-list-item-text {
                font-family: var(--heavy-font-family);
                font-weight: var(--heavy-font-weight);
                &.access-denied {
                  color: red;
                }
                &.would-have-access {
                  color: green;
                }
              }
            }
            .subscription-pricing-table-features-list-item-check {
              opacity: var(--light-text-opacity);
              position: relative;
              top: -3px;
            }
            .subscription-pricing-table-features-list-item-text {
              line-height: 1.2;
            }
          }
        }
      }
      .subscription-pricing-table-num-teams-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 60px;
        width: 100%;
        margin-top: 8px;
        .subscription-pricing-table-num-teams-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          .subscription-pricing-table-num-teams-button-group {
            display: flex;
            flex-direction: row;
            border: 1px solid #e6e6e6;
            border-radius: 4px;
            align-items: center;
            justify-content: center;
            margin-right: 6px;
            &.no-access {
              border: 1px solid red;
              .subscription-pricing-table-num-teams-input {
                color: red;
              }
            }
            .subscription-pricing-table-num-teams-button {
              padding: 4px 10px;
              opacity: var(--light-text-opacity);
            }
          }
          .subscription-pricing-table-num-teams-input,
          .subscription-pricint-table-num-teams-team-label {
            font-size: 16px;
            font-family: var(--heavy-font-family);
            font-weight: var(--heavy-font-weight);
            margin-top: 3px;
            .subscription-pricint-table-num-teams-unit-price-label {
              font-family: var(--regular-font-family);
              font-weight: var(--regular-font-weight);
              font-size: var(--small-font-size);
              opacity: var(--light-text-opacity);
            }
          }
        }
      }
    }
  }
}
