.team-code-explanation-modal {
  p,
  li {
    font-size: 1.1rem;
    line-height: 1.35rem;
    margin-bottom: 1rem;
  }
  .message-body-wrapper {
    border-radius: 8px;
    padding: 50px 20px 20px;
    background: #efefef;
    position: relative;
    margin: 0px 50px;
    .message-copy-button {
      position: absolute;
      top: 4px;
      right: 4px;
      padding: 2px;
    }
    .message-body {
      font-family: 'AvinerHeavy';
      font-size: 1rem;
      color: #333;
      p {
        line-height: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }
    button {
      display: flex;
      gap: 4px;
      align-items: center;
      div {
        visibility: hidden;
        font-size: 1.2rem;
      }
      &:hover div {
        visibility: visible;
      }
    }
  }
}
