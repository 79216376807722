$light-text-opacity: 0.5;
$heavy-font-family: 'AvinerHeavy';
$heavy-font-weight: 700;
$small-font-size: 13px;

:root {
  --light-text-opacity: 0.5;
  --heavy-font-family: 'AvinerHeavy';
  --heavy-font-weight: 700;
  --medium-font-weight: 500;
  --regular-font-family: 'AvinerLight';
  --regular-font-weight: 400;
  --small-font-size: 13px;
}

@keyframes badgeHighlightShimmer {
  0% {
    left: -100%;
    top: -150px;
  }
  100% {
    left: 100%;
    top: 150px;
  }
}

.subscription-pricing-table-wrapper {
  display: grid;
  width: 100%;
  @media screen and (min-width: 1200px) {
    & {
      grid-template-columns: repeat(auto-fit, calc(33.333% - 13.34px));
      gap: 20px;
    }
  }
  grid-template-columns: repeat(auto-fit, calc(100%));
  gap: 80px;
  .subscription-pricing-table-item {
    display: flex;
    flex-direction: column;
    &:not(.recommended) {
      padding-top: 60px;
    }
    &.recommended {
      background-color: rgba(26, 26, 26, 0.05);
      border: 1px solid rgba(26, 26, 26, 0.1);
      &:hover {
        .subscription-pricing-table-recommended {
          background-color: #21c87a;
          color: white;
        }
      }
      .subscription-pricing-table-recommended {
        background-color: white;
        color: #545a69;
        height: 24px;
        margin-bottom: 14px;
        width: auto;
        display: inline-block;
        padding-left: 4px;
        padding-right: 4px;
        font-size: 12px;
        font-weight: var(--heavy-font-weight);
        font-family: var(--heavy-font-family);
        line-height: 26px;
        border-radius: 4px;
        align-self: flex-end;
        position: relative;
        overflow: hidden;
        &::before {
          background: linear-gradient(
            180deg,
            rgba(130, 130, 130, 0) 0,
            rgba(130, 130, 130, 0.2) 25%,
            rgba(130, 130, 130, 0.3) 50%,
            rgba(130, 130, 130, 0.2) 75%,
            rgba(130, 130, 130, 0)
          );
          webkit-animation: badgeHighlightShimmer 3.5s;
          animation: badgeHighlightShimmer 2s;
          -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
          -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
          -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;

          content: '';
          display: block;
          height: 90px;
          left: -60%;
          position: absolute;
          top: -150px;
          -webkit-transform: rotate(-25deg);
          -ms-transform: rotate(-25deg);
          transform: rotate(-25deg);
          width: 200px;
        }
      }
    }
    padding: 20px;
    border-radius: 10px;
    flex: 1;
    &.disabled {
      opacity: 0.35;
    }
    &:not(.disabled):hover {
      background-color: #f6f6f6;
    }

    .subscription-pricing-table-title {
      font-weight: var(--heavy-font-weight);
      font-family: var(--heavy-font-family);
    }
    .subscription-pricing-table-start-at {
      opacity: var(--light-text-opacity);
      font-size: var(--small-font-size);
      height: 26px;
      display: table-cell;
      vertical-align: bottom;
      line-height: 1;
    }
    .subscription-pricing-table-price-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-top: 4px;
      .subscription-pricing-table-price-amount {
        font-size: 36px;
        line-height: 1;
        font-weight: var(--heavy-font-weight);
        font-family: var(--heavy-font-family);
      }
      .subscription-pricing-table-price-term {
        font-size: var(--small-font-size);
        line-height: 1;
        margin-bottom: 8px;
        opacity: var(--light-text-opacity);
        padding-left: 4px;
        padding-right: 4px;
      }
    }
    .subscription-pricing-table-subscripe-button-loader {
      margin: 14px 0;
      padding: 0.75rem 1rem;
      font-family: var(--heavy-font-family);
      font-size: 20px;
      font-weight: 500;
      width: 100%;
      text-align: center;
    }
    .subscription-pricing-table-subscribe-button {
      width: 100%;
      color: black !important;
      font-weight: 500;
      font-family: var(--heavy-font-family);
      font-size: 20px;
      margin: 14px 0;
      &:hover {
        color: white !important;
      }
      &.current-plan {
        &.btn-outline-primary {
          &.disabled {
            &:hover {
              background-color: transparent !important;
              cursor: no-drop;
              color: black !important;
            }
          }
        }
      }
    }
    .subscription-pricing-table-features-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      .subscription-pricing-table-features-header,
      .subscription-pricing-table-features-list-item
        .subscription-pricing-table-features-list-item-text {
        font-size: 16px;
      }
      .subscription-pricing-table-features-list-wrapper {
        margin: 0px -8px;
        .subscription-pricing-table-features-list-item {
          padding: 0px 8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 12px;
          &.access-highlighted {
            background-color: #e6e6e6;
            border-radius: 4px;
            .subscription-pricing-table-features-list-item-text {
              font-family: var(--heavy-font-family);
              font-weight: var(--heavy-font-weight);
              &.access-denied {
                color: red;
              }
              &.would-have-access {
                color: green;
              }
            }
          }
          .subscription-pricing-table-features-list-item-check {
            opacity: var(--light-text-opacity);
            position: relative;
            top: -3px;
          }
          .subscription-pricing-table-features-list-item-text {
            line-height: 1.2;
          }
        }
      }
    }
    .subscription-pricing-table-num-teams-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      height: 60px;
      width: 100%;
      margin-top: 8px;
      .subscription-pricing-table-num-teams-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        .subscription-pricing-table-num-teams-button-group {
          background-color: white;
          display: flex;
          flex-direction: row;
          border: 1px solid #e6e6e6;
          border-radius: 4px;
          align-items: center;
          justify-content: center;
          margin-right: 6px;
          &.no-access {
            border: 1px solid red;
            .subscription-pricing-table-num-teams-input {
              color: red;
            }
          }
          .subscription-pricing-table-num-teams-button {
            padding: 4px 10px;
            opacity: var(--light-text-opacity);
          }
        }
        .subscription-pricing-table-num-teams-input,
        .subscription-pricint-table-num-teams-team-label {
          font-size: 16px;
          font-family: var(--heavy-font-family);
          font-weight: var(--heavy-font-weight);
          margin-top: 3px;
          .subscription-pricint-table-num-teams-unit-price-label {
            font-family: var(--regular-font-family);
            font-weight: var(--regular-font-weight);
            font-size: var(--small-font-size);
            opacity: var(--light-text-opacity);
          }
        }
      }
    }
  }
}

.subscription-pricing-modal-not-recommended-tier {
  .subscription-pricing-modal-not-recommended-tier-wrapper {
    display: flex;
    margin: 30px 0 0 0;
    padding: 0px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    ul.subscription-pricing-modal-not-recommended-tier-list {
      // flex-direction: column;
      li {
        text-align: left;
        font-family: var(--heavy-font-family);
        font-size: 1rem;
      }
    }
  }
}
