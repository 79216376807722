.mobile-phone {
  margin: auto;
  margin-top: 0px;
  padding: 10px;
  width: 42vh;
  height: 85vh;
  box-shadow: 0 0 20px #e2e2e2;
  border-radius: 30px;
  background: #ffffff;
  display: flex;
  justify-content: center;
}

.mobile-phone .screen {
  width: 100%;
  height: 100%;
  background: #002036;
  border-radius: 30px;
  overflow-y: auto;
  padding: 30px 10px 10px;
}

.mobile-phone .brove {
  z-index: 1;
  width: 150px;
  height: 20px;
  background: white;
  position: absolute;
  margin: 0 auto;
  border-radius: 0 0 20px 20px;
}

.mobile-phone .speaker {
  width: 60px;
  height: 5px;
  background: #d2d2d2;
  display: block;
  margin: auto;
  margin-top: 5px;
  border-radius: 20px;
}
