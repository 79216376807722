@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@500;700&display=swap');

section.reviews {
  padding: 90px 0 45px;
}

@media (max-width: 768px) {
  section.reviews {
    padding: 60px 0 45px;
  }
}

section.reviews .title {
  margin-bottom: 40px;
}

section.reviews .title h2 {
  font-size: 55px;
  line-height: 60px;
  font-weight: bold;
}

section.reviews .item {
  padding: 40px 15px;
}

@media (max-width: 768px) {
  section.reviews .item {
    padding: 40px 8px;
  }
}

section.reviews .item .card {
  position: relative;
  background: url('../img/quote-fade.png');
  background-size: 115% 125%;
  background-position: center;
  z-index: 2;
  padding: 75px 30px 30px;
  border: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  border-bottom: 3px solid #eccc43;
}

section.reviews .item .card .card-inner {
  position: relative;
  z-index: 2;
}

section.reviews .item .card .quote-circle {
  position: absolute;
  top: -30px;
  width: 80px;
  border-radius: 50%;
  height: 80px;
  background: #eccc43;
  display: grid;
  place-items: center;
  z-index: 2;
}

section.reviews .item .card .quote-circle img {
  width: 45px;
  height: 45px;
  object-fit: contain;
}

section.reviews .item .card .stars {
  margin-bottom: 20px;
}

section.reviews .item .card .stars img {
  width: 150px;
  height: 20px;
  object-fit: contain;
  object-position: left;
}

section.reviews .item .card .img-info {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

section.reviews .item .card .img-info .img-box {
  flex: 0 0 70px;
  margin-right: 20px;
}

section.reviews .item .card .text {
  font-size: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

section.reviews .owl-nav button {
  width: 55px;
  height: 55px;
  border-radius: 50% !important;
  border: 2px solid #eccc43 !important;
  position: absolute;
  top: 50%;
  transition: 0.3s;
}

section.reviews .owl-nav button:hover {
  background: #eccc43 !important;
}

section.reviews .owl-nav button.owl-prev {
  transform: translate(0, -50%);
  left: -75px !important;
}

section.reviews .owl-nav button.owl-next {
  transform: translate(0, -50%);
  right: -75px !important;
}

@media (max-width: 1024px) {
  section.reviews .owl-nav button {
    transform: unset !important;
    position: unset !important;
    margin: 0 15px !important;
  }
}

section.reviews .owl-nav button img {
  object-fit: contain;
  width: 30px;
}

.header.header-secondary {
  position: static;
  background: #0e0e44;
  padding: 22px 0;
}

.header.header-secondary .header-inner .login-btns .signup-btn {
  font-family: 'AvinerLight';
  border-radius: 6px;
  padding: 0.5rem 1.9rem;
  margin: 0 20px;
  border: 1px solid #eccc43;
  color: #eccc43;
}

.header.header-secondary .header-inner .login-btns .signup-btn:hover {
  color: white;
  background: #eccc43;
}

.header.header-secondary .header-inner .login-btns .login-btn {
  font-family: 'AvinerLight';
  color: white;
  border-radius: 6px;
  padding: 0.5rem 1.9rem;
}

.footer-secondary .footer {
  margin: 0;
  padding-top: 30px;
}

section.pricing {
  padding: 70px 0;
  background: #fff;
}

@media (min-width: 1400px) {
  section.pricing .container {
    max-width: 1350px;
  }
}

section.pricing p {
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
}

section.pricing .title {
  text-align: center;
}

section.pricing .title h1 {
  font-size: 60px !important;
  line-height: 82px !important;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: bold;
  color: #000;
}

section.pricing .title h1 span {
  color: #eccc43;
}

section.pricing .title p {
  font-size: 19px;
  line-height: 30px;
  color: #444;
}

section.pricing .pricing-wrapper {
  margin-top: 100px;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-template-rows: repeat(var(--grid-rows, 1), min-content);
  .price-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 0;
    border-left: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    margin-top: 80px;
    border-right: 1px solid #e0e0e0;
    @media only screen and (min-width: 991px) {
      margin-top: 0;
      border-right: none;
    }
    div {
      font-family: 'Source Sans 3', sans-serif;
      font-weight: 400;
      color: #999999;
      font-size: 16px;
    }
    .logo {
      position: absolute;
      top: -37.5px;
      left: 50%;
      margin-left: -37.5px;
      padding: 12.5px;
      border-radius: 50%;
      height: 75px;
      &.grey-logo {
        background-color: grey;
      }
      &.blue-logo {
        background-color: #212529;
      }
      &.gold-logo {
        background-color: #f6d30d;
      }
      img {
        width: 50px;
        height: 50px;
        margin: 0px auto;
        object-fit: contain;
      }
    }
    h5 {
      font-size: 24px;
      line-height: 35px;
      font-family: 'Source Sans 3', sans-serif;
      color: black;
      color: #000;
      font-weight: bold;
      margin: 0;
    }
    strong {
      color: black;
      font-family: 'Source Sans 3', sans-serif;
      font-size: 24px;
    }
  }
  .grid-item {
    padding: 8px 25px;
    width: 100%;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    @media only screen and (min-width: 991px) {
      border-right: none;
    }
    &:not(.text) {
      justify-content: center;
    }
    &.text.checkbox-each {
      justify-content: center;
    }
    &.even {
      background-color: #f9f9f9;
    }
    .svg-icon > svg {
      width: 22px;
      height: 22px;
    }
    &.text {
      min-height: 67px;
    }
  }
  .blank-cell-bottom-border {
    border-bottom: 1px solid #e0e0e0;
  }
  .blank-cell-top-border {
    border-top: 1px solid #e0e0e0;
  }
  .blank-cell-left-border {
    border-left: 1px solid #e0e0e0;
  }
  .subscribe-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    border-left: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    @media only screen and (min-width: 991px) {
      border-right: none;
    }
  }
  .full-width-last {
    border-right: 1px solid #e0e0e0;
  }
  @media only screen and (min-width: 991px) {
    grid-auto-flow: column;
    grid-template-columns: repeat(4, 25%);
  }
  .price-card {
    border: 1px solid #e0e0e0;
    border-top: 3px solid #b2b2b2;
    border-right: 1px solid transparent;
    position: relative;
    @media only screen and (max-width: 991px) {
      border-right: 1px solid #e0e0e0;
    }
  }

  @media only screen and (max-width: 991px) {
    margin-top: 0;
    .row {
      gap: 70px;
    }
  }
}

section.pricing .pricing-wrapper .price-card .price-header {
  padding: 60px 25px 25px;
  min-height: 183px;
  border-bottom: 1px solid #e0e0e0;
}

@media only screen and (max-width: 767px) {
  section.pricing .pricing-wrapper .price-card .price-header {
    min-height: unset;
  }
}

section.pricing .pricing-wrapper .price-card .price-header .grey-logo {
  position: absolute;
  left: 50%;
  top: -40px;
  transform: translate(-50%, 0);
}

section.pricing .pricing-wrapper .price-card .price-header .red-strike {
  position: relative;
  font-size: 18px;
  max-width: 180px;
  margin: auto;
}

section.pricing .pricing-wrapper .price-card .price-header .red-strike::before {
  content: '';
  position: absolute;
  width: 115px;
  height: 5px;
  background: #ff0000;
  left: 0;
  border-radius: 6px;
  top: 50%;
  transform: translate(0, -50%);
}

section.pricing .pricing-wrapper .price-card .price-header img {
  height: 75px;
  width: 75px;
  object-fit: contain;
  border: 5px solid white;
  border-radius: 50%;
}

section.pricing .pricing-wrapper .price-card .price-header h5 {
  font-size: 24px;
  line-height: 35px;
  font-family: 'Source Sans 3', sans-serif;
}

section.pricing .pricing-wrapper .price-card .price-header p {
  color: #999999;
}

section.pricing .pricing-wrapper .price-card .price-header strong {
  color: black;
  font-family: 'Source Sans 3', sans-serif;
  font-size: 24px;
}

section.pricing .pricing-wrapper .price-card .checkboxes {
  text-align: center;
}

section.pricing .pricing-wrapper .price-card .checkboxes .checkbox-each {
  padding: 0 25px;
  line-height: 55px;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 991px) {
  section.pricing .pricing-wrapper .price-card .checkboxes .checkbox-each {
    justify-content: flex-start;
  }

  section.pricing
    .pricing-wrapper
    .price-card
    .checkboxes
    .checkbox-each
    .svg-icon {
    margin-right: 15px;
  }
}

section.pricing
  .pricing-wrapper
  .price-card
  .checkboxes
  .checkbox-each:nth-child(odd) {
  background: #f9f9f9;
}

section.pricing .pricing-wrapper .text {
  font-size: 20px;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: bold;
  color: #555;
}

@media only screen and (max-width: 991px) {
  section.pricing
    .pricing-wrapper
    .price-card
    .checkboxes
    .checkbox-each
    .text {
    display: block;
    line-height: 20px;
    text-align: left;
  }
}

section.pricing .pricing-wrapper .price-card .checkboxes .subscribe-btn {
  padding: 45px 20px;
  min-height: 145px !important;
  justify-content: center;
}

section.pricing .pricing-wrapper .price-card .checkboxes .subscribe-btn .btn {
  width: 100%;
  max-width: 225px;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 8px;
  color: white;
  font-size: 20px;
  border: 1px solid #eccc43;
}

@media only screen and (max-width: 767px) {
  section.pricing .pricing-wrapper .price-card .checkboxes .subscribe-btn .btn {
    max-width: 100%;
  }
}

section.pricing
  .pricing-wrapper
  .price-card
  .checkboxes
  .subscribe-btn
  .outline-btn {
  color: #eccc43;
}

section.pricing
  .pricing-wrapper
  .price-card
  .checkboxes
  .subscribe-btn
  .outline-btn:hover {
  background: #eccc43;
  color: white;
}

section.pricing .pricing-wrapper .price-card.last {
  border-right: 2px solid #e0e0e0;
}

section.pricing .pricing-wrapper .price-card.last .red-strike::before {
  background: transparent;
}

section.pricing .pricing-wrapper .price-card.first {
  border: none;
}

@media only screen and (max-width: 991px) {
  section.pricing .pricing-wrapper .price-card.first {
    display: none;
  }
}

section.pricing .pricing-wrapper .price-card.first .price-header {
  visibility: hidden;
  opacity: 0;
  min-height: 184px;
}

section.pricing .pricing-wrapper .price-card.first .checkboxes.lists {
  border: 1px solid #e0e0e0;
  text-align: left;
  border-right: none;
}

section.pricing
  .pricing-wrapper
  .price-card.first
  .checkboxes.lists
  .checkbox-each {
  line-height: 22px;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

section.pricing .pricing-wrapper .price-card.first .checkboxes.lists .text {
  font-size: 15px;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: bold;
  color: #555;
  display: block;
}

section.pricing .cta {
  margin-top: 90px;
  margin-bottom: 30px;
  padding: 65px 15px 40px;
  border: 1px solid #e0e0e0;
  position: relative;
  background: url(../img/cta-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

section.pricing .cta .cta-logo {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: -45px;
  border: 4px solid white;
}

section.pricing .cta .cta-logo img {
  width: 75px;
  height: 75px;
  object-fit: contain;
}

section.pricing .cta .cta-content h4 {
  font-size: 31px;
  font-family: 'Source Sans 3', sans-serif;
  line-height: 35px;
}

@media only screen and (max-width: 991px) {
  section.pricing .cta .cta-content h4 {
    font-size: 24px;
    line-height: 30px;
  }
}

section.pricing .cta .cta-content .price {
  margin: 10px 0 15px;
  font-size: 18px;
}

section.pricing .cta .cta-content .price strong {
  font-size: 31px;
  font-family: 'Source Sans 3', sans-serif;
}

@media only screen and (max-width: 991px) {
  section.pricing .cta .cta-content .price strong {
    font-size: 24px;
  }
}

section.pricing .cta .cta-btn .btn {
  width: 100%;
  max-width: 225px;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 8px;
  color: white;
  font-size: 20px;
  border: 1px solid #eccc43;
  color: #eccc43;
}

section.pricing .cta .cta-btn .btn:hover {
  color: white;
  background: #eccc43;
}

section.pricing {
  .segmented-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .segmented-content {
      padding: 0.25rem;
      background-color: rgba(226, 232, 240, 1);
      border-radius: 1rem;
      button {
        background-color: rgba(0, 0, 0, 0);
        border: none;
        padding: 0.75rem 1.5rem;
        font-weight: 900;
        font-size: 1rem;
        line-height: 1.25rem;
        border-radius: 0.75rem;
        font-family: 'Source Sans 3', sans-serif;
        &.selected {
          background-color: #fff;
        }
        span.savings {
          background-color: rgb(134 239 172);
          border-radius: 999px;
          font-weight: 400;
          font-size: 0.875rem;
          padding: 1px 6px;
          margin-left: 8px;
        }
      }
    }
  }
}
