// Here you can add other styles
// $item-pop-in = popAnimation

.screen-height {
  height: 100vh;
}

// order-list
.lists {
	display: flex;
  flex: 1;
	margin: 0;
	padding: 0;

  .itemList {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    counter-reset: list;
    z-index: 0;
    padding: 0;
    margin: 0;

    .item {
      list-style: none;
      margin: .4rem 0;
      border-radius: 3px;
      overflow: hidden;
      position: relative;
      padding: 0;
      -webkit-user-select: none;
      user-select: none;
      display: flex;
      cursor: -webkit-grab;
      cursor: grab;
      box-shadow: 1px 2px 4px rgba(0,0,0,.3);
      transition-timing-function: ease;
      transition-duration: .4s;
      transition-property: transform opacity;

      .content {
        padding: 1rem 2rem 1rem 1rem;
        margin: 0;
        display: flex;
        flex: 1;
        align-items: center;
      }

      &.touched {
        opacity: .5 !important;
        cursor: -webkit-grabbing;
        cursor: grabbing;
        z-index: -1;
        transform: scale(0.99);
      }

      &:before {
        content: counter(list);
        position: relative;
        padding: 0 1rem;
        top: 0;
        color: var(--color);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        counter-increment: list;
        background: var(--secondary);
      }
    }
  }
}


// DropZone
.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    margin-bottom: 1rem;
}

// thumbs styles
.thumbs-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  .thumb {
    position: relative;
    display: inline-flex;
    border: 1px solid #eaeaea;
    border-radius: 2;
    margin-right: 8;
    width: 200px;
    height: 200px;
    box-sizing: border-box;
  }

  .thumb-inner {
    display: flex;
    min-width: 0;
    overflow: hidden;
    margin: 0px auto;
  }

  img {
    display: block;
    width: 200px;
    height: 100%;
    max-height: 200px;
    max-width: 100%;
  }

  video {
    width: 100%;
    height: 200px;
  }

  .thumb-button {
    position: absolute;
    right: 10px;
    bottom: 10px;
    border: 0;
    color: #fff;
    background: #e55353;
    border-radius: .325em;
    cursor: pointer;
    padding: 0px 7px 3px;
  }
}

// Theme Preview

.wrapper {
  width: 65%;
  margin: 0px auto;
  min-height: 400px;
  padding: 20px 0px 0;
  display: flex;
  border-radius: 12px;

  h4 {
    text-align: center;
  }

  .content {
    border-radius: 30px 30px 0 0;
    padding: 10px 20px;
    width: 100%;

    .card-theme {
      width: 100%;
      min-height: 160px;
      border-radius: 6px;
      padding: 6px 8px;
    }

    p {
      font-size: 12px;
    }

    small {
      display: block;
      display: flex;
      margin-bottom: 1rem;
      justify-content: space-between;
    }

    button {
      display: block;
      padding: 10px 0;
      text-align: center;
      border-radius: 6px;
      width: 100%;
    }
  }
}
