.question-categories-section {
  height: 100%;
  display: flex;
  overflow-y: scroll;
  .accordion {
    display: flex;
    flex-direction: column;
    width: 100%;
    & > button {
      background: white;
      border: none;
      position: sticky;
      height: 40px;
    }
  }
}
