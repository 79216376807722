section.how-it-works {
  .how-it-works-content-wrapper {
    padding: 10px;
    background: white;
    position: relative;
    border-radius: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    box-shadow: 0px -3px 20px rgba(86, 91, 132, 0.03),
      0px 90px 321px rgba(86, 91, 132, 0.04),
      0px 32.8515px 117.17px rgba(86, 91, 132, 0.0275993),
      0px 15.9488px 56.884px rgba(86, 91, 132, 0.0222516),
      0px 7.81839px 27.8856px rgba(86, 91, 132, 0.0177484),
      0px 3.0914px 11.026px rgba(86, 91, 132, 0.0124007);

    @media screen and (min-width: 768px) and (max-width: 991px) {
      height: 300px;
      padding: 10px;
      font-size: 0.9rem;
      line-height: 1rem;
      .how-it-works-text {
        font-size: 0.9rem;
        line-height: 1rem;
      }
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      height: 400px;
      padding: 20px;
      .how-it-works-text {
        font-size: 1.2rem;
        line-height: 1.4rem;
      }
    }
    @media screen and (min-width: 1200px) and (max-width: 1399px) {
      height: 440px;
      padding: 20px;
      .how-it-works-text {
        font-size: 1.2rem;
        line-height: 1.4rem;
      }
    }
    @media screen and (min-width: 1400px) {
      height: 500px;
      padding: 20px;
      .how-it-works-text {
        font-size: 1.2rem;
        line-height: 1.4rem;
      }
    }
    .how-it-works-number {
      position: absolute;
      border: 4px solid #eccc43;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 9px;
      height: 56px;
      width: 56px;
      left: -10px;
      top: -21px;
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(15px);
      -webkit-backdrop-filter: blur(15px);
      font-weight: 700;
      font-size: 36px;
      z-index: 2;
      color: white;
      background: #08081c;
      font-family: 'AvinerHeavy';
      padding-top: 6px;
    }
    .how-it-works-image-wrapper {
      img {
        width: 165px;
        height: 165px;
        border-radius: 20px;
        object-fit: cover;
      }
    }
    @media screen and (min-width: 768px) {
      flex-direction: column !important;
      margin-bottom: 0px !important;

      .how-it-works-image-wrapper {
        padding: 0 0 100% 0;
        position: relative;
        overflow: hidden;
        width: 100%;
        margin: 0 0 20px 0 !important;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    .how-it-works-text {
      font-family: 'AvinerHeavy';
      @media screen and (max-width: 768px) {
        font-size: 1rem;
        line-height: 1.2rem;
      }
    }
  }
  .row {
    & > div {
      .how-it-works-content-wrapper {
        flex-direction: row;
        .how-it-works-image-wrapper {
          margin: 0 20px 0 0;
        }
      }
      &:nth-child(even) {
        .how-it-works-content-wrapper {
          flex-direction: row-reverse;
          .how-it-works-image-wrapper {
            margin: 0 0 0 20px;
          }
        }
      }
    }
  }
}
