.text-page {
  padding-bottom: 80px;
  h1 {
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
  }
  h2 {
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.6rem;
    text-decoration: underline;
  }
  h3 {
    font-family: 'Times New Roman', Times, serif;
    font-size: 1rem;
    font-style: italic;
  }
  .text-center {
    text-align: center;
  }
  p {
    margin-bottom: 1.2rem;
  }
  p,
  div,
  li,
  td,
  th {
    font-family: 'Times New Roman', Times, serif;
    color: #162630 !important;
    line-height: 1.2rem;
    font-size: 1rem;
  }
  p {
    &.list-header {
      margin-bottom: 0.25rem;
    }
  }
  table {
    margin: 20px 0;
    td,
    th {
      border: 1px solid black;
    }
    td {
      padding: 8px;
    }
    th {
      text-align: center;
      font-weight: bold;
      text-transform: none;
      padding: 10px 8px 4px;
    }
  }
  ul {
    li {
      line-height: 1.5rem;
      padding-left: 26px;
      text-indent: -26px;
    }
  }
  .indented-section {
    padding-left: 36px;
  }
}
